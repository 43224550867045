import React, { Suspense, useState } from "react";
import cookie_js from "cookie_js";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Backdrop,
  createTheme,
  Snackbar,
  ThemeProvider,
} from "@mui/material";

import "./App.css";
import "react-virtualized/styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Main from "./pages/Main";
import { createStoreData } from "store";
import Header from "shared/Header";
import { get_employee_info } from "api";
import { LoadingIndicator } from "shared/LoadingIndicator";
import CreateDataBaseRecord from "components/CreateDataBaseRecord";
import { AdministrationDialog } from "components/Administration/AdministrationDialog";
import { useTranslation } from "react-i18next";

const ClassifiersAndCodelists = React.lazy(() =>
  import("pages/ClassifiersAndCodelists")
);
const Directories = React.lazy(() => import("pages/Directories"));
const CreateClassifier = React.lazy(() => import("pages/CreateClassifier"));
const ViewClassifier = React.lazy(() => import("pages/ViewClassifier"));
const CreateDirectory = React.lazy(() => import("pages/CreateDirectory"));
const VariablesAndDefinitions = React.lazy(() =>
  import("pages/VariablesAndDefinitions")
);
const CreateVariable = React.lazy(() => import("pages/CreateVariable"));
const ViewVariable = React.lazy(() => import("pages/ViewVariable"));
const StatisticalIndicators = React.lazy(() =>
  import("pages/StatisticalIndicators")
);
const CreateIndicator = React.lazy(() => import("pages/CreateIndicator"));
const Admin = React.lazy(() => import("pages/Admin"));
const ViewDirectory = React.lazy(() => import("pages/ViewDirectory"));
const CreateDefinition = React.lazy(() => import("pages/CreateDefinition"));
const Definitions = React.lazy(() => import("pages/Definitions"));
const ViewDefinition = React.lazy(() => import("pages/ViewDefinition"));
const ViewIndicator = React.lazy(() => import("pages/ViewIndicator"));

const CheckIsAuthorized = ({ children }) => {
  const { employee } = useSelector((store) => store);

  return !!employee.isSuperAdmin ? children : <Navigate to="/" />;
};

function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { alert, backdrop } = useSelector((store) => store);

  React.useEffect(() => {
    let token = cookie_js.get("metadata_token");
    if (Boolean(token)) {
      dispatch(
        createStoreData({
          employee: { token: token },
        })
      );
      dispatch(get_employee_info());
    }
  }, [dispatch]);
  React.useEffect(() => {
    if (Boolean(alert.message)) {
      setOpen(true);
    }
  }, [alert]);

  const closeSnackbar = () => {
    setOpen(false);
    setTimeout(() => {
      dispatch(() =>
        createStoreData({ alert: { message: "", severity: "success" } })
      );
    }, 1000);
  };

  return (
    <div className="App">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ minWidth: 300 }}>
        <Alert
          variant="filled"
          severity={alert.severity}
          onClose={closeSnackbar}>
          {t(alert.message)}
        </Alert>
      </Snackbar>

      <Backdrop
        open={backdrop.open}
        onClick={() => {}}
        style={{ zIndex: 1000, backgroundColor: "#0000004d" }}>
        <LoadingIndicator />
      </Backdrop>

      <ThemeProvider theme={theme}>
        <AdministrationDialog />
        <CreateDataBaseRecord />

        <Suspense
          fallback={
            <div
              className="flex_box"
              style={{
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
              }}>
              <LoadingIndicator />
            </div>
          }>
          <BrowserRouter>
            <Header />

            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/definitions" element={<Definitions />} />
              <Route
                path="/variables&definitions"
                element={<VariablesAndDefinitions />}
              />
              <Route
                path="/classifiers&codelists"
                element={<ClassifiersAndCodelists />}
              />
              <Route path="/classifiers" element={<Directories />} />
              <Route
                path="/about-statistic"
                element={<StatisticalIndicators />}
              />
              <Route
                path="/administration"
                element={
                  <CheckIsAuthorized>
                    <Admin />
                  </CheckIsAuthorized>
                }
              />

              <Route
                path="/create-classifier"
                element={
                  <CheckIsAuthorized>
                    <CreateClassifier />
                  </CheckIsAuthorized>
                }
              />
              <Route
                path="/create-directory"
                element={
                  <CheckIsAuthorized>
                    <CreateDirectory />
                  </CheckIsAuthorized>
                }
              />
              <Route
                path="/create-indicator"
                element={
                  <CheckIsAuthorized>
                    <CreateIndicator />
                  </CheckIsAuthorized>
                }
              />
              <Route
                path="/create-variable"
                element={
                  <CheckIsAuthorized>
                    <CreateVariable />
                  </CheckIsAuthorized>
                }
              />
              <Route
                path="/create-definition"
                element={
                  <CheckIsAuthorized>
                    <CreateDefinition />
                  </CheckIsAuthorized>
                }
              />

              <Route path="/directories/:dbId" element={<ViewDirectory />} />
              <Route path="/classifiers/:dbId" element={<ViewClassifier />} />
              <Route path="/variables/:variableId" element={<ViewVariable />} />
              <Route
                path="/definitions/:definitionId"
                element={<ViewDefinition />}
              />
              <Route
                path="/about-statistic/:indicatorId"
                element={<ViewIndicator />}
              />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </ThemeProvider>
    </div>
  );
}

export default App;

const theme = createTheme({
  typography: {
    fontFamily: "Roboto Condensed",
    h1: {
      fontSize: 56,
      fontWeight: "bold",
    },
    h2: {
      fontSize: 28,
      fontWeight: "bold",
    },
    h3: {
      fontFamily: "Inter",
      fontWeight: "bold",
      fontSize: 24,
    },
    h4: {
      fontSize: 20,
      fontWeight: "bold",
      lineHeight: "32px",
    },
    h6: {
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontSize: 18,
    },
    body1: {
      fontFamily: "Open Sans",
      fontSize: 16,
    },
    body2: {
      fontFamily: "Roboto",
    },
    caption: {
      fontFamily: "Roboto",
      lineHeight: "161%",
    },
    button: {
      textTransform: "none",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: 16,
      color: "#FFFFFF",
    },
  },
});
