import React, { useState } from "react";
import chevronDown from "assets/SVG/chevron-down.svg";
import trash from "assets/SVG/bx-trash.svg";
import show from "assets/SVG/bx-show.svg";
import edit from "assets/SVG/bx-edit-alt.svg";
import {
  FormControl,
  Grid,
  Link,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

export default function DataTable({
  pageSize,
  setPageSize,
  pageData,
  pageNumber,
  setPageNumber,
  onView,
  onEdit,
  onDelete,
  tab,
  titlesArr,
  columnsArr,
  hidePageSizeSelect = false,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  // const theme = useTheme()
  // const xs = useMediaQuery
  const [isPageSizeSelect, setPageSizeSelect] = useState(false);
  const [minContainerWidth, setContainerMinWidth] = useState(960);

  React.useEffect(() => {
    if (titlesArr.length > 0) {
      let widthInPixels = 0;

      titlesArr.forEach((title) => {
        widthInPixels += Boolean(title.props.minWidth)
          ? title.props.minWidth
          : 0;
      });
      setContainerMinWidth(widthInPixels);
    }
  }, [titlesArr]);

  let ifPageCountMoreThanOne =
    Boolean(pageData?.pageCount) && pageData.pageCount > 1;
  return (
    <div style={{ overflow: "auto hidden" }}>
      <Grid
        container
        spacing={0}
        style={{
          border: "0.5px solid #757575",
          marginBottom: 40,
          minWidth: minContainerWidth,
        }}>
        {/* table header row */}
        <Grid item xs={12} container spacing={0}>
          {Boolean(titlesArr) &&
            titlesArr.map((title, titleIndex) =>
              titleIndex === 0 ? (
                <Grid
                  item
                  key={titleIndex}
                  {...title.props}
                  style={{
                    borderRight: "0.5px solid #757575",
                    borderBottom: "0.5px solid #757575",
                  }}>
                  <div
                    className="flex_box"
                    style={{
                      alignItems: "end",
                      height: "100%",
                    }}>
                    <Typography
                      variant="body1"
                      style={{
                        height: "100%",
                        padding: "26px 32px 8px 32px",
                      }}>
                      №
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "26px 32px 8px 32px",
                        borderLeft: "0.5px solid #757575",
                      }}>
                      {t(title.name) || title.name}
                    </Typography>
                  </div>
                </Grid>
              ) : (
                <Grid
                  item
                  key={titleIndex}
                  {...title.props}
                  className={classes.titleGridItem}
                  style={{
                    borderRight:
                      titlesArr.length === titleIndex + 1
                        ? "none"
                        : "0.5px solid #757575",
                  }}>
                  <Typography variant="body1">
                    {t(title.name) || title.name}
                  </Typography>
                </Grid>
              )
            )}
        </Grid>
        {Boolean(pageData?.data) &&
          pageData.data.length > 0 &&
          pageData.data.map((item, rowIndex) => {
            return (
              <Grid
                item
                xs={12}
                container
                spacing={0}
                key={rowIndex}
                className={
                  (rowIndex + 1) % 2 !== 0 ? classes.whiteRow : classes.blueRow
                }>
                {columnsArr.map((key, columnIndex) => {
                  let isCursorDefault = Boolean(
                    key === "administration" ||
                      key === t("general.view") ||
                      key === t("general.delete") ||
                      key === t("general.download")
                  );
                  return columnIndex === 0 ? (
                    <Grid
                      key={key + columnIndex}
                      {...titlesArr[columnIndex].props}
                      item
                      style={{
                        borderTop: rowIndex !== 0 ? "0.5px solid #CAD8FF" : 0,
                        cursor: isCursorDefault ? "default" : "pointer",
                      }}
                      onClick={() => onView(item)}>
                      <div
                        className="flex_box"
                        style={{
                          alignItems: "end",
                          height: "100%",
                        }}>
                        <Typography
                          variant="body1"
                          style={{
                            minWidth: 80,
                            height: "100%",
                            padding: "8px 32px",
                          }}>
                          {pageNumber > 1
                            ? (rowIndex + 1) * pageSize
                            : rowIndex + 1}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: "8px 32px",
                            borderLeft: "0.5px solid #CAD8FF",
                          }}>
                          {t(item[key]) || item[key]}
                        </Typography>
                      </div>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      key={key + columnIndex}
                      {...titlesArr[columnIndex].props}
                      className={classes.childrenGridItem}
                      style={{
                        borderRight: "none",
                        borderTop: rowIndex !== 0 ? "0.5px solid #CAD8FF" : 0,
                      }}>
                      {key === "administration" ? (
                        <div
                          className="flex_box"
                          style={{
                            width: "100%",
                            justifyContent: "flex-end",
                          }}>
                          <span
                            className="flex_box"
                            style={{
                              width: "100%",
                              maxWidth: 110,
                              justifyContent: "flex-end",
                            }}>
                            {tab === 0 && (
                              <img
                                style={{
                                  cursor: "pointer",
                                  marginRight: 25,
                                }}
                                src={show}
                                alt=""
                                onClick={() => onView(item)}
                              />
                            )}
                            <div
                              className="flex_box"
                              style={{ width: tab === 2 ? 20 : 65 }}>
                              <img
                                style={{ cursor: "pointer" }}
                                src={edit}
                                alt=""
                                onClick={() => onEdit(item)}
                              />
                              {tab !== 2 && (
                                <img
                                  style={{ cursor: "pointer", marginLeft: 25 }}
                                  src={trash}
                                  alt=""
                                  onClick={() => onDelete(item)}
                                />
                              )}
                            </div>
                          </span>
                        </div>
                      ) : t(key) === t("general.view") ? (
                        <Typography
                          variant="body1"
                          onClick={() => onView(item)}
                          style={{
                            color: "#1C5C91",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}>
                          {t(key)}
                        </Typography>
                      ) : t(key) === t("general.delete") ? (
                        <Typography
                          variant="body1"
                          onClick={() => onDelete(item)}
                          style={{
                            color: "#1C5C91",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}>
                          {t(key)}
                        </Typography>
                      ) : t(key) === t("general.download") ? (
                        <Link
                          underline="none"
                          download={item.name}
                          href={item.url}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#1C5C91",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}>
                            {t(key)}
                          </Typography>
                        </Link>
                      ) : (
                        <Typography
                          variant="body1"
                          onClick={() => onView(item)}>
                          {t(item[key]) || item[key]}
                        </Typography>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
      </Grid>

      <div
        className="flex_box"
        style={{
          minWidth: minContainerWidth,
          justifyContent: "flex-start",
        }}>
        {ifPageCountMoreThanOne && (
          <Pagination
            page={pageNumber}
            onChange={(_, page) => setPageNumber(page)}
            count={pageData.pageCount}
            siblingCount={2}
            boundaryCount={1}
            classes={{ root: classes.pagination }}
          />
        )}
        {!hidePageSizeSelect && (
          <div className="flex_box" style={{ width: 232 }}>
            <Typography variant="body2" style={{ width: 140, fontWeight: 500 }}>
              {t("forms.entriesPerPage")}
            </Typography>
            <FormControl fullWidth classes={{ root: classes.pageSizeSelect }}>
              <Select
                value={pageSize}
                open={isPageSizeSelect}
                onChange={(e) => setPageSize(e.target.value)}
                onClick={() => setPageSizeSelect((state) => !state)}
                IconComponent={() => (
                  <img
                    className="chevronDownIcon"
                    style={{
                      transform: `rotate(${
                        isPageSizeSelect ? "180deg" : "0deg"
                      })`,
                    }}
                    src={chevronDown}
                    alt=""
                  />
                )}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  separator: {
    color: "#000",
  },
  titleGridItem: {
    padding: "26px 32px 8px 32px",
    borderBottom: "0.5px solid #757575",
  },
  childrenGridItem: {
    padding: "8px 32px",
    borderLeft: "0.5px solid #CAD8FF",
  },
  pageSizeSelect: {
    height: 40,
    width: "70px !important",

    "& fieldset": {
      width: "100%",
      border: "1px solid #ECECF6",
      borderRadius: 6,
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
    "& .MuiSelect-select": {
      fontFamily: "Roboto !important",
      fontWeight: "500 !important",
      fontSize: "14px !important",
      color: "#000000 !important",
    },
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      height: 36,
      width: 35,
    },
  },
  whiteRow: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FAFAFA",
    },
  },
  blueRow: {
    backgroundColor: "#F6F8FF",
    "&:hover": {
      backgroundColor: "#E7EDFF",
    },
  },
});
