import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import close from "assets/SVG/Close.svg";
import ParseDataAndReturnForm from "components/ParseDataAndReturnForm";
import { createDataBaseRecord, get_record_info } from "api";
import { PrimaryButton } from "shared/PrimaryButton";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingIndicator } from "shared/LoadingIndicator";
import { createStoreData } from "store";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { styles } from "pages/ClassifiersAndCodelists";

export default function CreateDataBaseRecord() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    schemas,
    records,
    dataTables,
    dbAllRecordsTable,
    dataBaseRecordDialog,
    parentWordsDictionary,
  } = useSelector((store) => store);
  const [fields, setFields] = useState([]);
  const [validation, setValidation] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const {
    show,
    open,
    create,
    formId,
    onClose,
    recordId,
    dataBaseName,
    isHierarchical,
  } = dataBaseRecordDialog;
  const [isLoading, setLoading] = useState(false);
  const [parentModel, setParentModel] = useState("");
  const [getParentId, setParents] = useState({});

  React.useEffect(() => {
    if (Boolean(show && recordId)) {
      if (records[recordId]) {
        setInitialValues(records[recordId]);
      } else {
        setLoading(true);
        dispatch(
          get_record_info(recordId, (record) => {
            if (record) {
              setInitialValues(record);
              setLoading(false);
            }
          })
        );
      }
    }
  }, [show, recordId, records, open, dispatch]);
  React.useEffect(() => {
    if (
      typeof schemas[formId] !== "undefined" &&
      schemas[formId].schema.schema.fields?.length > 0
    ) {
      let newFields = [],
        models = {},
        validationObj = {},
        getParentSelectItems = () => {
          let variants = [],
            parentIds = {};
          if (
            dbAllRecordsTable[formId] &&
            Boolean(dbAllRecordsTable[formId].length)
          ) {
            dbAllRecordsTable[formId].forEach((column) => {
              if (column[0]?.label === "Код") {
                column.forEach((row, rowIndex) => {
                  if (Boolean(rowIndex)) {
                    variants.push({ value: row });
                  }
                });
              }
              if (column[0]?.label === "Наименование") {
                column.forEach((row, rowIndex) => {
                  if (Boolean(rowIndex)) {
                    variants[rowIndex - 1].label = row;
                  }
                });
              }
            });
            dbAllRecordsTable[formId][0].forEach((row, rowIndex) => {
              if (Boolean(rowIndex)) {
                parentIds[variants[rowIndex - 1].value] = row.recordInfo.id;
              }
            });
          }
          setParents(parentIds);
          return variants;
        };
      schemas[formId].schema.schema.fields.forEach((field) => {
        let type = field.model.split("-")[0],
          newField = {
            ...field,
            name: field.model,
            type,
            label: field.labelRu,
            multiline: Boolean(
              type !== "date" && parseInt(field.maxlength) >= 60
            ),
          };

        models[field.model] = "";
        validationObj[field.model] =
          type === "number"
            ? Yup.number().required(t("errors.fillTheInput"))
            : type === "text"
            ? Yup.string().required(t("errors.fillTheInput"))
            : null;

        if (parentWordsDictionary[String(field.labelRu).toLowerCase()]) {
          newField.type = "select";
          newField.variants = getParentSelectItems();
          setParentModel(field.model);
        }
        if (
          create ||
          !parentWordsDictionary[String(field.labelRu).toLowerCase()] ||
          (parentWordsDictionary[String(field.labelRu).toLowerCase()] &&
            records[recordId] &&
            records[recordId][field.model])
        ) {
          newFields.push(newField);
        }
      });
      setFields(newFields);
      if (create) {
        setValidation(Yup.object(validationObj));
        setInitialValues(models);
      }
    }
  }, [
    parentWordsDictionary,
    dbAllRecordsTable,
    isHierarchical,
    dataTables,
    recordId,
    records,
    schemas,
    formId,
    create,
    i18n,
    open,
    t,
  ]);

  function submitHandler(values) {
    let possibleFields = {};
    for (let fieldName in initialValues) {
      if (initialValues[fieldName]) {
        possibleFields[fieldName] = initialValues[fieldName];
      } else {
        let type = fieldName.split("-")[0];
        if (type === "date") {
          return dispatch(
            createStoreData({
              alert: { severity: "error", message: t("pleaseSelectTheDate") },
            })
          );
        }
        if (type === "radio") {
          return dispatch(
            createStoreData({
              alert: { severity: "error", message: t("fillAllInputs") },
            })
          );
        }
      }
    }
    let queryParams =
      parentModel && values[parentModel]
        ? { parentRecordId: getParentId[values[parentModel]] }
        : undefined;
    if (parentModel === "requiredParentSelect") {
      delete values["requiredParentSelect"];
    }
    dispatch(
      createDataBaseRecord(
        { ...values, ...possibleFields },
        formId,
        (success) => {
          if (success) {
            closeHandler(true);
          }
        },
        queryParams
      )
    );
  }

  const ActionComponent = () => {
    return (
      <div
        className="flex_box"
        style={{ width: "100%", justifyContent: "center" }}>
        {create && (
          <PrimaryButton
            variant="contained"
            type="submit"
            style={{
              width: 160,
            }}>
            {t("general.create")}
          </PrimaryButton>
        )}
      </div>
    );
  };
  const closeHandler = (isCreated = false) => {
    setInitialValues({});
    setParentModel("");
    setParents({});
    setFields([]);
    dispatch(
      createStoreData({
        dataBaseRecordDialog: { ...dataBaseRecordDialog, open: false },
      })
    );
    onClose(isCreated);
  };

  return (
    <Dialog open={open} onClose={() => closeHandler(false)}>
      <DialogContent className={classes.content}>
        <div className="flex_box" style={{ marginBottom: 20 }}>
          <Typography
            variant="h6"
            onClick={() => console.log(initialValues, validation, fields)}>
            {create
              ? t("forms.createRecordIn", { dataBaseName })
              : dataBaseName}
          </Typography>
          <img
            style={{ cursor: "pointer" }}
            src={close}
            alt="close_icon"
            onClick={() => closeHandler(false)}
          />
        </div>
        {Object.keys(initialValues).length > 0 ? (
          <ParseDataAndReturnForm
            data={fields}
            initialValues={initialValues}
            validationSchema={validation}
            submitHandler={submitHandler}
            actionComponent={<ActionComponent />}
            datePickerValue={(name) =>
              new Date(initialValues[name]).toUTCString()
            }
            changeDatePicker={(name, value) =>
              setInitialValues((fields) => ({
                ...fields,
                [name]: moment(value).format("DD MMMM YYYY"),
              }))
            }
            disableEverithing={show}
            enableReinitialize
            columns={1}
          />
        ) : isLoading ? (
          <div
            className="flex_box"
            style={{ width: "100%", justifyContent: "center" }}>
            <LoadingIndicator />
          </div>
        ) : (
          <Typography
            className={classes.error}
            style={{ marginTop: 20 }}
            variant="h5">
            {t("errors.schemaNotFound")}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  ...styles,
  content: {
    minWidth: 600,
  },
}));
