import {
  cutIntoPagesForPagination,
  getDataTableSample,
  getUrlString,
  makeRequest,
} from "api";

import { updateStoreData } from "store";
import { createStoreData } from "store";
import { getDataTableFromClassifierRecords, getTreeStructure } from "./utils";

export const get_inn_info = (inn, callback) => (dispatch) => {
  makeRequest(`/employee/inn-info/${inn}`, dispatch, "get").then(callback);
};
export const get_employee_info = () => (dispatch) => {
  makeRequest(`/employee/info`, dispatch, "get").then((employee) => {
    if (employee) {
      if (employee.name === "СуперАдминистратор") {
        dispatch(
          updateStoreData({
            key: "employee",
            data: { isSuperAdmin: true },
          })
        );
      }
      dispatch(createStoreData({ employeeInfo: employee }));
    }
  });
};
export const get_fk_variables =
  (callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/db/database/list?formTypes=${process.env.REACT_APP_DEFINITIONS}`,
      dispatch,
      "get"
    ).then((data) => {
      let definitions = data.result;
      makeRequest(
        `/db/database/list?formTypes=${process.env.REACT_APP_VARIABLES_IT}`,
        dispatch,
        "get"
      ).then((data) => {
        let ITVariables = data.result;
        makeRequest(
          `/db/database/list?formTypes=${process.env.REACT_APP_VARIABLES_CONCEPTUAL}`,
          dispatch,
          "get"
        ).then((data) => {
          let conceptualVariables = data.result,
            databases = definitions.concat(ITVariables, conceptualVariables);

          databases.map((db) => {
            let isDefinition =
              db.formType === process.env.REACT_APP_DEFINITIONS;
            db.path = isDefinition
              ? `/definitions/${db.id}`
              : `/variables/${db.id}`;
            return db;
          });

          dispatch(
            createStoreData({
              variablesList: cutIntoPagesForPagination(databases),
              variablesListSelect: databases,
            })
          );
          callback();
        });
      });
    });
  };
export const get_db_list =
  (params, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/db/database/list?${getUrlString(params)}`,
      dispatch,
      "get"
    ).then((data) => {
      if (data) {
        let storeData = {};
        if (params.formTypes === process.env.REACT_APP_CLASSIFIER) {
          storeData.classifiersList = cutIntoPagesForPagination(data.result);
          storeData.classifiersListSelect = data.result;
        }
        if (params.formTypes === process.env.REACT_APP_CATALOG) {
          storeData.directoriesList = cutIntoPagesForPagination(data.result);
          storeData.directoriesListSelect = data.result;
        }
        if (params.formTypes === process.env.REACT_APP_DEFINITIONS) {
          storeData.definitionsList = cutIntoPagesForPagination(data.result);
          storeData.definitionsListSelect = data.result;
        }
        if (params.formTypes === process.env.REACT_APP_INDICATORS) {
          storeData.indicatorsList = cutIntoPagesForPagination(data.result);
          storeData.indicatorsListSelect = data.result;
        }

        dispatch(createStoreData(storeData));
      }
      callback();
    });
  };
export const getClassifiersAndDirectories =
  (callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/db/database/list?${getUrlString({
        formTypes: process.env.REACT_APP_CLASSIFIER,
      })}`,
      dispatch,
      "get"
    ).then((data) => {
      let classifiers = data.result;
      makeRequest(
        `/db/database/list?${getUrlString({
          formTypes: process.env.REACT_APP_CATALOG,
        })}`,
        dispatch,
        "get"
      ).then((data) => {
        let directories = data.result,
          storeData = {
            classifiersListSelect: classifiers,
            directoriesListSelect: directories,
            classifiersAndDirectoriesList: cutIntoPagesForPagination(
              directories
                .concat(classifiers)
                .sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0)),
              10
            ),
          };
        dispatch(createStoreData(storeData));
        callback();
      });
    });
  };
export const get_db_meta_data = (formId) => (dispatch) => {
  makeRequest(`/db/GetMetadata/${formId}`, dispatch, "get").then((data) => {
    if (data) {
      dispatch(
        updateStoreData({
          key: "metaData",
          data: {
            [formId]: data.result,
          },
        })
      );
    }
  });
};
export const get_db_record_list = (params, callback) => (dispatch) => {
  makeRequest(`/db/record/list?${getUrlString(params)}`, dispatch, "get").then(
    (data) => {
      if (data) {
        dispatch(
          updateStoreData({
            key: "maxCounts",
            data: {
              [params.formId]: data.result.pageObject.rowCount,
            },
          })
        );
        dispatch(
          updateStoreData({
            key: "maxPages",
            data: {
              [params.formId]: data.result.pageObject.pageCount,
            },
          })
        );
        dispatch(
          updateStoreData({
            key: "dataTables",
            data: { [params.formId]: getDataTableSample(data.result) },
          })
        );
      }
      if (callback) {
        callback();
      }
    }
  );
};
export const getHierarchicalRecords =
  (params, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/db/record/list?${getUrlString(params)}`,
      dispatch,
      "get"
    ).then((data) => {
      if (data) {
        dispatch(
          updateStoreData({
            key: "maxCounts",
            data: {
              ["hierarchical&" + params.formId]:
                data.result.pageObject.rowCount,
            },
          })
        );
        dispatch(
          updateStoreData({
            key: "maxPages",
            data: {
              ["hierarchical&" + params.formId]:
                data.result.pageObject.pageCount,
            },
          })
        );
        dispatch(
          updateStoreData({
            key: `hierarchicals.${params.formId}`,
            data: { [params.page]: getTreeStructure(data.result) },
          })
        );
      }
      callback();
    });
  };
export const getHerarchicalChildRecords = (params, callback) => (dispatch) => {
  makeRequest(`/db/record/list?${getUrlString(params)}`, dispatch, "get").then(
    (data) => {
      if (data) {
        callback(getTreeStructure(data.result));
      } else {
        callback(false);
      }
    }
  );
};
export const get_employee_list = (params) => (dispatch) => {
  makeRequest(`/employee/list?${getUrlString(params)}`, dispatch, "get").then(
    (data) => {
      if (data) {
        dispatch(
          updateStoreData({ key: "employeeListSelect", data: data.result.data })
        );
        dispatch(
          updateStoreData({
            key: "dataTables",
            data: {
              employeeList: getDataTableFromClassifierRecords(
                data.result.data,
                false,
                [
                  ["№"],
                  [
                    {
                      label: "admin_page.full_name",
                      model: "name",
                    },
                  ],
                  [
                    {
                      label: "general.action",
                      model: "administration",
                    },
                  ],
                ]
              ),
            },
          })
        );
      }
    }
  );
};
export const get_organization_list = () => (dispatch) => {
  makeRequest("/Organisation/list", dispatch, "get").then((data) => {
    if (data) {
      let filteredOrganizations = data.result.filter(
        (organization) => organization.inn !== "13131313131313"
      );
      dispatch(
        createStoreData({ organizationListSelect: filteredOrganizations })
      );
      dispatch(
        updateStoreData({
          key: "dataTables",
          data: {
            organizationList: getDataTableFromClassifierRecords(
              filteredOrganizations,
              false,
              [
                ["№"],
                [
                  {
                    label: "admin_page.organizationName",
                    model: "name",
                  },
                ],
                [
                  {
                    label: "forms.organizationINN",
                    model: "inn",
                  },
                ],
                [
                  {
                    label: "general.action",
                    model: "administration",
                  },
                ],
              ]
            ),
          },
        })
      );
    }
  });
};
export const get_employee = (id) => (dispatch) => {
  makeRequest(`/employee/${id}`, dispatch, "get").then((data) => {
    if (data) {
      dispatch(updateStoreData({ key: "employeesDB", data: { [id]: data } }));
    }
  });
};
export const get_db_schema =
  (id, callback = () => {}) =>
  (dispatch) => {
    makeRequest(`/Db/get-schema/${id}`, dispatch, "get").then((data) => {
      if (data) {
        callback(
          Boolean(data.result?.schema) ? data.result.schema.model : false
        );
        dispatch(
          updateStoreData({
            key: "schemas",
            data: {
              [id]:
                Boolean(data.result?.schema) &&
                Boolean(Object.keys(data.result.schema).length)
                  ? data.result
                  : {
                      schema: {
                        model: {},
                        schema: { fields: [] },
                      },
                    },
            },
          })
        );
      }
    });
  };
export const get_record_info =
  (id, callback = () => {}) =>
  (dispatch) => {
    makeRequest(`/Db/get-record/${id}`, dispatch, "get").then((data) => {
      if (data) {
        callback(data.result);
        dispatch(
          updateStoreData({ key: "records", data: { [id]: data.result } })
        );
      }
      callback();
    });
  };
export const get_document = (fileId, callback) => (dispatch) => {
  makeRequest(`Db/File/Get?fileId=${fileId}`, dispatch, "get").then((json) => {
    if (json) {
      dispatch(
        updateStoreData({
          key: "downloadedFiles",
          data: {
            [fileId]: json.result,
          },
        })
      );
      callback(json.result);
    } else {
      callback(false);
    }
  });
};
export const getAllRecords =
  (params, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `Db/GetFormAllRecords?${getUrlString(params)}`,
      dispatch,
      "get"
    ).then((json) => {
      if (json) {
        dispatch(
          updateStoreData({
            key: "dbAllRecords",
            data: { [params.formId]: json.result },
          })
        );
        dispatch(
          updateStoreData({
            key: "dbAllRecordsTable",
            data: { [params.formId]: getDataTableSample(json.result) },
          })
        );
      }
      callback();
    });
  };
