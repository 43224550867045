import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function SortModeSelect({ onChange, isFacetedClassifier }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const [sortMode, setSortMode] = useState("active");
  return (
    <div className='flex_box' style={{ width: md ? 215 : "auto" }}>
      <Typography
        variant='body2'
        style={{ fontWeight: 500, paddingTop: 3, whiteSpace: "nowrap" }}
      >
        {isFacetedClassifier
          ? `${t("general.catalogs")}:`
          : `${t("general.show")}:`}
      </Typography>
      <FormControl fullWidth classes={{ root: classes.sortModeSelect }}>
        <Select
          value={sortMode}
          onChange={(e) => {
            let value = e.target.value;
            setSortMode(value);
            onChange(value);
          }}
        >
          <MenuItem value='all'>{t("general.all")}</MenuItem>
          <MenuItem value='active'>{t("general.active")}</MenuItem>
          <MenuItem value='inactive'>{t("general.inactive")}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  sortModeSelect: {
    height: 20,
    "& .MuiSelect-select": {
      fontFamily: "Roboto !important",
      fontWeight: "500 !important",
      fontSize: "14px !important",
      color: "#1C5C91 !important",
      height: "100% !important",
      padding: "0px 32px 0px 14px !important",
    },
    "& .MuiSelect-icon": { color: "#1C5C91" },
    "& fieldset": {
      border: "none",
    },
  },
}));
