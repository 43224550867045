import { MenuItem, Typography } from "@mui/material";
import { getArrFromObj } from "api";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "shared/PrimaryButton";
import { StandardTextInput } from "shared/StandardTextInput";
import { ValidatedTextInput } from "shared/StandardTextInput";
import * as Yup from "yup";

export default function DataBaseRole({
  isCreate,
  isUpdate,
  createOrUpdate,
  dbRole,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dbList } = useSelector((store) => store);
  const [formsList, setFormsList] = useState([]);
  const [selectedForms, setSelectedForm] = useState([]);
  const roleInitialValues = {
    name: dbRole.name ? dbRole.name : "",
    description: dbRole.description ? dbRole.description : "",
  };

  React.useEffect(() => {
    if ((isUpdate, Boolean(dbRole.formAccessList))) {
      setSelectedForm(dbRole.formAccessList);
    }
  }, [isUpdate, dbRole]);
  React.useEffect(() => {
    if (dbList.length > 0) {
      let groupedList = dbList.reduce((calculator, db) => {
        if (Boolean(calculator[db.formType])) {
          calculator[db.formType].push(db);
        } else {
          calculator[db.formType] = [db];
        }
        return calculator;
      }, {});
      setFormsList(getArrFromObj(groupedList, true));
    }
  }, [dbList, dispatch]);

  const handleSubmit = (values) => {
    createOrUpdate(
      isCreate
        ? { ...values, formAccessList: selectedForms }
        : {
            ...values,
            dbRoleId: dbRole.dbRoleId,
            formAccessList: selectedForms,
          },
      isUpdate
    );
  };

  const getTitle = {
    [process.env.REACT_APP_CATALOG]: t("general.catalogs"),
    [process.env.REACT_APP_CLASSIFIER]: t("general.classifiers"),
    [process.env.REACT_APP_VARIABLES_IT]: t("general.IT_variables"),
    [process.env.REACT_APP_VARIABLES_CONCEPTUAL]: t(
      "general.conceptual_variables"
    ),
    [process.env.REACT_APP_DEFINITIONS]: t("general.definitions"),
    [process.env.REACT_APP_INDICATORS]: t("general.abount_statistic"),
    [process.env.REACT_APP_DATABASE]: t("general.dataBase"),
  };
  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        name: Yup.string().required(t("errors.fillTheInput")),
        description: Yup.string().required(t("errors.fillTheInput")),
      })}
      initialValues={roleInitialValues}>
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <ValidatedTextInput
            name="name"
            label={t("forms.clientName")}
            value={formik.values["name"]}
            onChange={formik.handleChange}
            variant="standard"
            style={{ marginBottom: 40 }}
          />
          <ValidatedTextInput
            name="description"
            label={t("forms.description")}
            value={formik.values["description"]}
            onChange={formik.handleChange}
            variant="standard"
            style={{ marginBottom: 40 }}
          />

          <div className="flex_box" style={{ flexWrap: "wrap" }}>
            {formsList.length > 0 ? (
              formsList.map((dbGroupe) => {
                return (
                  <div
                    className="flex_box"
                    key={dbGroupe.key}
                    style={{ width: "100%", flexWrap: "wrap" }}>
                    <Typography
                      variant="h6"
                      style={{
                        width: "100%",
                        margin: "10px 0px",
                      }}>
                      {getTitle[dbGroupe.key]}
                    </Typography>
                    {dbGroupe.value.map((dataBase) => {
                      let selected = selectedForms.filter(
                          (form) => form.formId === dataBase.id
                        )[0],
                        canWatch = Boolean(selected),
                        canEdit = Boolean(selected) && selected.canEdit;

                      return (
                        <div
                          style={{ width: `calc(50% - 20px)` }}
                          key={dataBase.id}>
                          <StandardTextInput
                            style={{
                              marginBottom: 40,
                            }}
                            label={dataBase.name}
                            value={
                              canEdit
                                ? "canEdit"
                                : canWatch
                                ? "canWatch"
                                : "denied"
                            }
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value === "denied") {
                                return setSelectedForm((state) =>
                                  state.filter(
                                    (db) => db.formId !== dataBase.id
                                  )
                                );
                              } else {
                                setSelectedForm((state) => [
                                  ...state,
                                  {
                                    formId: dataBase.id,
                                    canEdit: value === "canEdit",
                                  },
                                ]);
                              }
                            }}
                            variant="standard"
                            fullWidth
                            select>
                            <MenuItem value="denied">
                              {t("forms.withoutAccess")}
                            </MenuItem>
                            <MenuItem value="canWatch">
                              {t("forms.view")}
                            </MenuItem>
                            <MenuItem value="canEdit">
                              {t("forms.fullAccess")}
                            </MenuItem>
                          </StandardTextInput>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <Typography
                variant="h6"
                style={{ textAlign: "center", width: "100%" }}>
                {t("errors.thereIsNoDataBaseFound")}
              </Typography>
            )}
          </div>

          <div
            className="flex_box"
            style={{ width: "100%", justifyContent: "center" }}>
            <PrimaryButton
              type="submit"
              style={{ width: "50%" }}
              variant="contained"
              // disabled={
              //   !Boolean(
              //     roleInitialValues.name !== formik.values["name"] ||
              //       roleInitialValues.description !==
              //         formik.values["description"]
              //   )
              // }
            >
              {isCreate ? t("general.create") : t("general.save")}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}
