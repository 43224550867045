import {
  Container,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Logo from "assets/SVG/Logo_Stat.svg";
import { PrimaryButton } from "shared/PrimaryButton";
import chevronDownWhite from "assets/SVG/chevron-down_white.svg";
import { makeStyles } from "@mui/styles";
import AuthorizationDialog from "components/AuthorizationDialog";
import { useDispatch, useSelector } from "react-redux";
import { createStoreData } from "store";
import { appAxios } from "api";
import cookie_js from "cookie_js";
import { initialState } from "store/initialState";
import { useTranslation } from "react-i18next";
import ConfirmRemoveDialog from "components/ConfirmRemoveDialog";
import { NavLink } from "react-router-dom";
import moment from "moment";
import momentLocales from "localization/config";
import "moment/locale/ru";

export default function Header() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { appLanguage, employee, employeeInfo, openAuthorizationDialog } =
    useSelector((store) => store);
  const [isLangSelect, setLangSelect] = useState(false);
  const [account, setAccount] = useState({ name: "", nickname: "" });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    confirm: () => {},
  });

  React.useEffect(() => {
    moment.locale("ru", momentLocales["ru"]);
  }, []);
  React.useEffect(() => {
    if (
      Object.keys(employee).length > 0 &&
      Object.keys(employeeInfo).length > 0
    ) {
      let nameArr = employeeInfo.name.split(" ");
      setAccount({
        name: employeeInfo.name,
        nickname: `${nameArr[0].split("")[0].toUpperCase()}${
          nameArr[1] ? nameArr[1].split("")[0].toUpperCase() : ""
        }`,
      });
    } else {
      setAccount({ name: "", nickname: "" });
    }
  }, [employeeInfo, employee]);

  const logoutHandler = () => {
    cookie_js.remove("metadata_token");
    appAxios.defaults.headers["Authorization"] =
      process.env.REACT_APP_GUEST_TOKEN;
    dispatch(
      createStoreData({
        ...initialState,
        employee: { token: process.env.REACT_APP_GUEST_TOKEN },
      })
    );
    setAccount({ name: "", nickname: "" });
  };
  return (
    <div style={{ backgroundColor: "#1C5C91" }} id="back-to-top-anchor">
      <AuthorizationDialog
        dialog={openAuthorizationDialog}
        handleClose={() =>
          dispatch(
            createStoreData({
              openAuthorizationDialog: { open: false, onSignIn: () => {} },
            })
          )
        }
      />
      <ConfirmRemoveDialog
        open={confirmDialog.open}
        closeHandler={() =>
          setConfirmDialog((state) => ({ open: false, confirm: state.confirm }))
        }
        confirmHandler={confirmDialog.confirm}
        logout
      />

      <Container>
        <div className="flex_box">
          <div className="flex_box">
            <NavLink to="/">
              <img src={Logo} style={{ marginRight: 10 }} alt="logo" />
            </NavLink>
            <NavLink to="/" style={{ textDecoration: "none" }}>
              <Typography variant="body1" style={{ color: "#FFFFFF" }}>
                {t("general.header_title")}
              </Typography>
            </NavLink>
          </div>
          <div
            className="flex_box"
            style={{ width: "40%", justifyContent: "flex-end" }}>
            {account.name ? (
              <div className="flex_box">
                <div
                  className="flex_box employee_ava"
                  style={{ justifyContent: "center" }}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#FFFFFF",
                      fontWeight: 700,
                      height: 17,
                      textAlign: "center",
                    }}>
                    {account.nickname}
                  </Typography>
                </div>
                <Typography
                  variant="caption"
                  style={{ marginLeft: 8, color: "#FFFFFF" }}>
                  {account.name}
                </Typography>
                <PrimaryButton
                  onClick={() =>
                    setConfirmDialog({ open: true, confirm: logoutHandler })
                  }
                  style={{ marginLeft: 20 }}>
                  <Typography
                    variant="caption"
                    style={{
                      textDecoration: "underline",
                      color: "#FFFFFF",
                    }}>
                    {t("general.logout")}
                  </Typography>
                </PrimaryButton>
              </div>
            ) : (
              <PrimaryButton
                className={classes.loginBtn}
                variant="outlined"
                onClick={() =>
                  dispatch(
                    createStoreData({
                      openAuthorizationDialog: {
                        open: true,
                        onSignIn: () => {},
                      },
                    })
                  )
                }>
                {t("general.login")}
              </PrimaryButton>
            )}

            <FormControl
              fullWidth
              classes={{ root: classes.LangSelect }}
              style={{ marginLeft: 20 }}>
              <Select
                value={appLanguage}
                open={isLangSelect}
                onChange={(e) => {
                  let lang = e.target.value;
                  i18n.changeLanguage(lang);
                  dispatch(createStoreData({ appLanguage: lang }));
                  if (lang !== "en") {
                    moment.locale(lang, momentLocales[lang]);
                  } else {
                    moment.locale(lang);
                  }
                }}
                onClick={() => setLangSelect((state) => !state)}
                IconComponent={() => (
                  <img
                    className={classes.chevronDownIcon}
                    style={{
                      transform: `rotate(${isLangSelect ? "180deg" : "0deg"})`,
                    }}
                    src={chevronDownWhite}
                    alt=""
                  />
                )}>
                <MenuItem value="ru">RU</MenuItem>
                <MenuItem value="en">EN</MenuItem>
                <MenuItem value="kg">KG</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  loginBtn: {
    width: 60,
    height: 30,
    color: "#FFFFFF !important",
    borderColor: "#FFFFFF !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
  },
  LangSelect: {
    height: 30,
    width: "40px !important",

    "& fieldset": {
      width: "100%",
      borderRadius: 6,
      border: "none",
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
    "& .MuiSelect-select": {
      padding: "3px 2px 0px 6px !important",
      fontFamily: "Roboto !important",
      fontWeight: "400 !important",
      fontSize: "12px !important",
      color: "#ffffff !important",
    },
  },
  chevronDownIcon: {
    position: "absolute",
    width: 13,
    height: 13,
    top: 10,
    right: 4,
  },
}));
