import { get_employee_list, makeRequest } from "api";
import { createStoreData } from "store";
import { get_db_record_list, get_organization_list } from "./GET_requests";
import { getDBRoleList } from "./POST_requests";

export const delete_employee = (id) => (dispatch) => {
  makeRequest(`/employee/delete/${id}`, dispatch, "delete").then((data) => {
    dispatch(
      createStoreData({
        alert: {
          severity: "success",
          message: "alerts.successfullyDeleted",
        },
      })
    );
    dispatch(get_employee_list({ pageNumber: 1, pageSize: 10 }));
  });
};
export const delete_organization = (id) => (dispatch) => {
  makeRequest(`/organisation/delete/${id}`, dispatch, "delete").then((data) => {
    if (data) {
      dispatch(
        createStoreData({
          alert: {
            severity: "success",
            message: "alerts.successfullyDeleted",
          },
        })
      );
      dispatch(get_organization_list());
    }
  });
};
export const delete_db_role = (roleId) => (dispatch) => {
  makeRequest(`/Db/DbRole/delete/${roleId}`, dispatch, "delete").then(
    (data) => {
      if (data) {
        dispatch(
          createStoreData({
            alert: {
              severity: "success",
              message: "alerts.successfullyDeleted",
            },
          })
        );
        dispatch(getDBRoleList());
      }
    }
  );
};
export const delete_db_form = (id, callback) => (dispatch) => {
  makeRequest(`Db/delete/form/${id}`, dispatch, "delete").then((data) => {
    if (data) {
      callback(true);
    }
    callback();
  });
};
export const delete_db_record = (id, params) => (dispatch) => {
  makeRequest(`/Db/delete/record/${id}`, dispatch, "delete").then((data) => {
    if (data) {
      dispatch(get_db_record_list(params));
      dispatch(
        createStoreData({
          alert: {
            severity: "success",
            message: "alerts.successfullyDeleted",
          },
        })
      );
    }
  });
};
export const delete_documents = (data, callback) => (dispatch) => {
  let promiseArr = data.map((fileId) =>
    makeRequest(`/Db/File/Delete?fileId=${fileId}`, dispatch, "delete")
  );

  Promise.all(promiseArr)
    .then((json) => {
      if (json) {
        callback(true);
      }
    })
    .catch((error) => {
      console.error(error);
      callback(false);
    });
};
