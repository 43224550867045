import cookie_js from "cookie_js";

let token = cookie_js.get("metadata_token");

export const initialState = {
  appLanguage: process.env.REACT_APP_LANGUAGE,
  employee: {
    token: token ? token : process.env.REACT_APP_GUEST_TOKEN,
    isAuth: Boolean(token),
  },
  employeeInfo: {},
  employeeList: {},
  employeesDB: {},
  organizationList: {},
  classifiersList: {},
  directoriesList: {},
  dbList: [],
  nesting: {},
  dbRoleList: [],
  dbAllRecords: {},
  dbAllRecordsTable: {},
  dataTables: {},
  schemas: {},
  records: {},
  maxCounts: {
    dbList: 0,
  },
  maxPages: {},
  metaData: {},
  dataTableSamples: {},
  classifierRecords: {},
  hierarchicals: {},
  classifiersListSelect: [],
  employeeListSelect: [],
  directoriesListSelect: [],
  variablesListSelect: [],
  definitionsListSelect: [],
  indicatorsListSelect: [],
  downloadedFiles: {},
  classifiersAndDirectoriesList: {},
  recordsSearchText: { dbId: "", value: "" },
  hierarchicalsSearchText: { dbId: "", value: "" },
  openAuthorizationDialog: { open: false, onSignIn: () => {} },
  alert: {
    severity: "success",
    message: "",
  },
  backdrop: {
    open: false,
  },
  dataBaseRecordDialog: {
    open: false,
    onClose: () => {},
    formId: false,
    dataBaseName: false,
    recordId: false,
    create: false,
    show: false,
    isHierarchical: false,
  },
  AdministrationDialog: {
    open: false,
    onCreateOrUpdate: () => {},
    title: "",
    create: false,
    show: false,
    edit: false,
    remove: false,
    data: false,
  },
  administrationTab: 0,
  parentWordsDictionary: {
    родитель: true,
    parent: true,
  },
};
