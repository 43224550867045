/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import close from "assets/SVG/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import { Employee, Organization } from "components/Administration";
import { get_employee } from "api";
import { createStoreData } from "store";
import DataBaseRole from "./DataBaseRole";
import { updateStoreData } from "store";

export function AdministrationDialog() {
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [itemDetails, setItemDetails] = useState({ id: "" });
  const { create, show, edit, data, title, onCreateOrUpdate, open } =
    store.AdministrationDialog;

  React.useEffect(() => {
    if (edit || show) {
      switch (store.administrationTab) {
        case 0: {
          if (Boolean(store.employeesDB[data.id])) {
            setItemDetails(store.employeesDB[data.id]);
          } else {
            dispatch(get_employee(data.id));
          }
          break;
        }
        case 1: {
          setItemDetails(data);
          break;
        }
        case 2: {
          setItemDetails(data);
          break;
        }
        default: {
          return dispatch(
            createStoreData({
              alert: {
                message:
                  "Нет обработчика в AdministrationDialog.jsx для этой вкладки!",
                severity: "error",
              },
            })
          );
        }
      }
    } else {
      setItemDetails({ id: "" });
    }
  }, [data, edit, show, store.employeesDB]);

  const handleClose = () => {
    dispatch(
      updateStoreData({
        key: "AdministrationDialog",
        data: {
          open: false,
        },
      })
    );
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent
        style={{ width: store.administrationTab === 2 ? 600 : 400 }}>
        <div className="flex_box" style={{ alignItems: "start" }}>
          <div style={{ width: 500 }}>
            <Typography variant="h3" style={{ marginBottom: show ? 0 : 20 }}>
              {title}
            </Typography>
            {show && (
              <Typography
                variant="body1"
                style={{ color: "#788090", marginBottom: 24 }}>
                {data.inn}
              </Typography>
            )}
          </div>
          <img
            style={{ cursor: "pointer" }}
            src={close}
            alt="close_icon"
            onClick={handleClose}
          />
        </div>

        {store.administrationTab === 0 && (
          <Employee
            onCreateOrUpdate={onCreateOrUpdate}
            employee={itemDetails}
            isLoading={(edit || show) && itemDetails.id !== data.id}
            isShow={show}
            isCreate={create}
            isUpdate={edit}
          />
        )}

        {store.administrationTab === 1 && (
          <Organization
            createOrUpdate={onCreateOrUpdate}
            organization={itemDetails}
            isCreate={create}
            isLoading={edit && itemDetails.id !== data.id}
          />
        )}

        {store.administrationTab === 2 && (
          <DataBaseRole
            dbRole={itemDetails}
            isCreate={create}
            isUpdate={edit}
            createOrUpdate={onCreateOrUpdate}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
