import {
  Breadcrumbs,
  Container,
  Link,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { SearchTextInput } from "shared";
import searchIcon from "assets/SVG/bx-search.svg";
import DataTable from "components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  filterBySearch,
  getClassifiersAndDirectories,
  sortByExpiration,
  delete_db_form,
} from "api";
import { createStoreData } from "store";
import { useTranslation } from "react-i18next";
import SortModeSelect from "components/SortModeSelect";
import ConfirmRemoveDialog from "components/ConfirmRemoveDialog";
import { LoadingIndicator } from "shared/LoadingIndicator";
import expandDownIcon from "assets/SVG/еxpand_more.svg";

export default function ClassifiersAndCodelists() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { classifiersAndDirectoriesList, employee } = useSelector(
    (store) => store
  );
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const [isLoading, setLoading] = useState();
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortmode, setSortmode] = useState("active");
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    confirm: () => {},
  });
  const [tableProps, setTableProps] = useState({
    titlesArr: [{ name: "general.Name", props: { xs: 12 } }],
    columnsArr: ["name"],
  });
  const [isMultiActionOpen, setMultiAction] = useState(false);

  React.useEffect(() => {
    if (employee.isSuperAdmin) {
      setTableProps({
        titlesArr: [
          { name: "general.Name", props: { xs: 10 } },
          { name: "general.action", props: { xs: 2 } },
        ],
        columnsArr: ["name", "general.delete"],
      });
    } else {
      setTableProps({
        titlesArr: [{ name: "general.Name", props: { xs: 12 } }],
        columnsArr: ["name"],
      });
    }
  }, [employee, t]);
  React.useEffect(() => {
    if (
      !Array.isArray(classifiersAndDirectoriesList) &&
      Object.keys(classifiersAndDirectoriesList).length > 0
    ) {
      if (sortmode === "all") {
        return setPageData({
          pageCount: classifiersAndDirectoriesList[pageNumber].pageCount,
          data: classifiersAndDirectoriesList[pageNumber].data,
        });
      }
      setPageData({
        pageCount: classifiersAndDirectoriesList[pageNumber].pageCount,
        data: sortByExpiration(
          "data.Date_end",
          classifiersAndDirectoriesList[pageNumber].data,
          sortmode
        ),
      });
    }
  }, [pageNumber, classifiersAndDirectoriesList, sortmode]);
  React.useEffect(() => {
    if (
      !Array.isArray(classifiersAndDirectoriesList) &&
      Object.keys(classifiersAndDirectoriesList).length === 0
    ) {
      setLoading(true);
      dispatch(getClassifiersAndDirectories(() => setLoading(false)));
    }
  }, [classifiersAndDirectoriesList, dispatch, t]);
  const searchFilter = (text) => {
    if (Boolean(Object.keys(classifiersAndDirectoriesList).length)) {
      setPageData({
        pageCount: pageData.pageCount,
        data: filterBySearch(
          classifiersAndDirectoriesList[pageNumber].data,
          text
        ),
      });
    } else {
      dispatch(
        createStoreData({
          alert: { message: t("errors.no_data_found"), severity: "error" },
        })
      );
    }
    setSearch(text);
  };
  function removeHandler(db) {
    setConfirmDialog({
      open: true,
      confirm: () =>
        dispatch(
          delete_db_form(db.id, (success) => {
            if (success) {
              dispatch(getClassifiersAndDirectories());
            }
          })
        ),
    });
  }
  function showHandler(db) {
    navigate(
      db.formType === process.env.REACT_APP_CATALOG
        ? `/directories/${db.id}`
        : `/classifiers/${db.id}`
    );
  }

  let showTable =
      !isLoading && !Array.isArray(pageData) && pageData?.data.length > 0,
    showSortModeSelect =
      !Array.isArray(classifiersAndDirectoriesList) &&
      classifiersAndDirectoriesList[pageNumber]?.data?.length > 0;
  return (
    <Container>
      <Breadcrumbs
        classes={{ separator: classes.separator }}
        style={{ marginTop: 32, marginBottom: 50 }}>
        <NavLink style={{ textDecoration: "none", color: "#1C5C91" }} to="/">
          <Typography
            variant="body1"
            style={{ lineHeight: "150%", borderBottom: "1px solid #1C5C91" }}>
            {t("general.metadata_title")}
          </Typography>
        </NavLink>
        <Link
          style={{ textDecoration: "none", lineHeight: "150%", color: "#000" }}>
          <Typography variant="body1">
            {t("general.classifiersAndCodeLists")}
          </Typography>
        </Link>
      </Breadcrumbs>
      <div className="flex_box" style={{ marginBottom: 45, flexWrap: "wrap" }}>
        <div
          className="flex_box"
          style={{ width: md ? "100%" : "auto", marginBottom: md ? 20 : 0 }}>
          <Typography variant="h2" style={{ marginRight: 40 }}>
            {t("general.classifiersAndCodeLists")}
          </Typography>
          {showSortModeSelect && (
            <SortModeSelect
              onChange={(value) => {
                setSortmode(value);
                if (value === "all") {
                  setPageData({
                    pageCount: pageData.pageCount,
                    data: classifiersAndDirectoriesList[pageNumber].data,
                  });
                }
                if (value !== "all") {
                  setPageData({
                    pageCount: pageData.pageCount,
                    data: sortByExpiration(
                      "data.Date_end",
                      classifiersAndDirectoriesList[pageNumber].data,
                      value
                    ),
                  });
                }
              }}
            />
          )}
        </div>
        <div
          className="flex_box"
          style={{ width: md ? "100%" : 466, justifyContent: "flex-end" }}>
          <SearchTextInput
            value={search}
            onChange={(e) => searchFilter(e.target.value)}
            placeholder={t("general.name_search")}
            style={{ width: 286 }}
            InputProps={{
              endAdornment: <img src={searchIcon} alt="search icon" />,
            }}
          />
          {employee.isSuperAdmin && (
            <Select
              value={"default"}
              className={classes.multiActionSelect}
              open={isMultiActionOpen}
              onClick={() => setMultiAction((open) => !open)}
              IconComponent={() => {
                return (
                  <img
                    className={classes.expandDownIcon}
                    style={{
                      transform: `rotate(${isMultiActionOpen ? 180 : 0}deg)`,
                      borderLeft: !isMultiActionOpen ? "1px solid #FFFFFF" : 0,
                      borderRight: isMultiActionOpen ? "1px solid #FFFFFF" : 0,
                    }}
                    src={expandDownIcon}
                    alt="expand down icon"
                  />
                );
              }}>
              <MenuItem value="default" style={{ display: "none" }}>
                {t("general.new")}
              </MenuItem>
              <MenuItem
                value="new_record"
                onClick={() => navigate("/create-classifier")}>
                {t("create_page.create_classifier")}
              </MenuItem>
              <MenuItem
                value="upload_excel"
                onClick={() => navigate("/create-directory")}>
                {t("create_page.create_directory")}
              </MenuItem>
            </Select>
          )}
        </div>
      </div>
      {showTable ? (
        <DataTable
          titlesArr={tableProps.titlesArr}
          columnsArr={tableProps.columnsArr}
          pageData={pageData}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          onView={showHandler}
          onDelete={removeHandler}
          hidePageSizeSelect
        />
      ) : isLoading ? (
        <div className="flex_box" style={{ justifyContent: "center" }}>
          <LoadingIndicator />
        </div>
      ) : (
        <Typography variant="h5" className={classes.error}>
          {t("errors.classifiersAndCodelists_not_found")}
        </Typography>
      )}
      <ConfirmRemoveDialog
        open={confirmDialog.open}
        closeHandler={() =>
          setConfirmDialog((state) => ({ open: false, confirm: state.confirm }))
        }
        confirmHandler={confirmDialog.confirm}
      />
    </Container>
  );
}

export const styles = {
  separator: {
    color: "#000",
  },
  error: {
    color: "#757575",
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
  },
  dataBaseName: {
    width: 600,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    lineHeight: "150%",
    color: "#000",
  },
  deleteBtn: {
    fontWeight: 400,
    color: "#ED1528",
    marginLeft: 20,
  },
  multiActionSelect: {
    height: 45,
    backgroundColor: "#1C5C91",
    border: "none",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 16,
    color: "#FFFFFF",
    marginLeft: 20,
    "& .MuiSelect-select": {
      paddingRight: "10px !important",
    },
  },
  expandDownIcon: {
    cursor: "pointer",
    pointerEvents: "auto",
    padding: "0px 10px",
  },
};
const useStyles = makeStyles(styles);
