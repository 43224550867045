export const getShortString = (string, maxLength, isLink = false) => {
  if (string.length < maxLength) {
    return string;
  }

  if (isLink) {
    let start = string.slice(0, maxLength - 9),
      end = string.split("").reverse().join("").slice(0, 6);
    return `${start}...${end}`;
  } else {
    let strArr = string.split("."),
      start = strArr[0].slice(0, maxLength - (10 + strArr[1].length)),
      end = `${strArr[0].split("").reverse().join("").slice(0, 6)}.${
        strArr[1]
      }`;
    let result = `${start}...${end}`;
    return result;
  }
};

export const getUrlString = (obj) => {
  let url = "";
  let keys = Object.keys(obj).filter((key) => Boolean(obj[key]));

  keys.forEach(
    (key, index) =>
      (url += `${key}=${obj[key]}${keys.length === index + 1 ? "" : "&"}`)
  );

  return url;
};

export const cutIntoPagesForPagination = (array, pageSize = 12) => {
  if (array.length === 0) {
    return [];
  }
  let paginationData = array.reduce(
    (calculator, organization) => {
      let pageNumber = Object.keys(calculator).length;
      if (calculator[pageNumber].data.length <= pageSize) {
        let pageData = calculator[pageNumber].data;
        pageData.push(organization);
        calculator[pageNumber].data = pageData;
      } else {
        calculator[pageNumber + 1] = {
          data: [organization],
          pageCount: Math.ceil(array.length / pageSize),
        };
      }
      return calculator;
    },
    { 1: { data: [], pageCount: Math.ceil(array.length / pageSize) } }
  );

  return paginationData;
};

export function getArrFromObj(obj, withKey) {
  let arr = [];
  for (let key in obj) {
    if (withKey) {
      arr.push({ key, value: obj[key] });
    } else {
      arr.push(obj[key]);
    }
  }
  return arr;
}

export function getDataTableSample(record_list_result) {
  const { labels, models } = record_list_result;

  let dataTableSample = [];

  getArrFromObj(labels, true).forEach((title, index) => {
    if (index === 0) {
      dataTableSample.push([{ label: "№", model: "№" }]);
    }
    dataTableSample.push([
      Boolean(title.value) ? { label: title.value, model: title.key } : "",
    ]);
  });

  models.forEach((record, recordIndex) => {
    dataTableSample.map((column, columnIndex) => {
      if (columnIndex === 0) {
        column.push({
          rowNumber: recordIndex + 1,
          recordInfo: record,
        });
      } else {
        column.push(record.model[column[0].model]);
      }
      return column;
    });
  });

  return dataTableSample;
}

export function getDataTableFromClassifierRecords(
  rowsArr,
  isClassifierFaceted,
  columns = [
    ["№"],
    [
      {
        label: isClassifierFaceted ? "general.directoryName" : "general.Name",
        model: "name",
      },
    ],
  ]
) {
  rowsArr.forEach((record, rowIndex) => {
    columns.map((column, columnIndex) => {
      if (columnIndex === 0) {
        column.push({ rowNumber: rowIndex + 1, recordInfo: record });
      } else {
        column.push(
          Boolean(record[column[0].model])
            ? record[column[0].model]
            : column[0].model
        );
      }
      return column;
    });
  });

  return columns;
}

export const getComplicatedClassifierRecords = (model) => {
  let recordsArr = [];

  for (let key in model) {
    let stringArr = model[key].split("&");
    recordsArr.push({
      model: key,
      [stringArr[0].split("=")[0]]: stringArr[0].split("=")[1],
      [stringArr[1].split("=")[0]]: stringArr[1].split("=")[1],
    });
  }

  return recordsArr;
};

export const getFacetedClassifierRecords = (model, directoriesList) => {
  let recordsID = [],
    recordsModel = [],
    newDirectories = directoriesList.map((directory) => ({ ...directory })),
    classifierRecords = [];

  for (let key in model) {
    recordsID.push(model[key].split("&")[0].split("=")[1]);
    recordsModel.push(key);
  }

  newDirectories.forEach((directory, index) => {
    if (recordsID.indexOf(directory.id) !== -1) {
      directory.model = recordsModel[recordsID.indexOf(directory.id)];
      classifierRecords.push(directory);
    }
  });
  return classifierRecords;
};

export async function getBase64(file) {
  return new Promise((resolve, reject) => {
    let fileNameArr = file.name.split(".");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(
        `data:application/${fileNameArr[fileNameArr.length - 1]};base64,${
          reader.result.split(",")[1]
        }`
      );
    reader.onerror = (error) => reject(error);
  });
}

export async function getBinary(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export async function getParsedDocuments(documents) {
  let promisses = documents.map((obj) => getBase64(obj.file));
  let b64Arr = await Promise.all(promisses).then(
    (newDocuments) => newDocuments
  );

  return documents.map((obj, index) => ({
    name: obj.file.name,
    description: obj.description,
    fileBase64: b64Arr[index],
  }));
}

export function filterBySearch(data, stringForFilter) {
  if (stringForFilter && data.length > 0) {
    let newData = data.map((item) => ({ ...item }));

    return newData.filter((item) => {
      return item.name.match(new RegExp(`^${stringForFilter}`, "gi"));
    });
  } else {
    return data;
  }
}

export function searchInSortedData(sortedData, stringForFilter) {
  if (stringForFilter && sortedData.length > 0) {
    let newSortedData = sortedData.map((item) => ({ ...item }));
    return newSortedData.filter((groupe) => {
      let newGroupe = groupe.value.filter((variable) =>
        variable.name.match(new RegExp(`^${stringForFilter}`, "gi"))
      );
      if (newGroupe.length > 0) {
        groupe.value = newGroupe;
        return true;
      } else {
        return false;
      }
    });
  } else {
    return sortedData;
  }
}

export function sortByExpiration(getKey, data, mode) {
  return data.filter((dataBase) => {
    return mode === "active"
      ? !Boolean(dataBase.disabled)
      : Boolean(dataBase.disabled);
    // let key = typeof getKey === "string" ? getKey : getKey(dataBase.formType);
    //   date = getObjectValueWithKey(key, dataBase.metaData);

    // if (
    //   Boolean(date) &&
    //   new Date(date) !== "Invalid Date" &&
    //   !isNaN(new Date(date))
    // ) {
    //   return mode === "active"
    //     ? new Date(date).getTime() >= new Date().getTime()
    //     : new Date(date).getTime() < new Date().getTime();
    // } else {
    //   return mode === "active";
    // }
  });
}

export function getObjectValueWithKey(stringPath, obj) {
  let pathArr = stringPath.split(".");

  if (pathArr.length === 0) {
    alert(
      'Путь передан в не правильном формате! Правильный формат "keyStr.keyStr.keyStr"'
    );
    return;
  }
  const researchObj = (object) => {
    if (pathArr.length === 1) {
      return object[pathArr[0]];
    } else {
      let newObj = object[pathArr[0]];
      pathArr.shift();
      return researchObj(newObj);
    }
  };

  return researchObj(obj);
}

export const checkIfDBAlreadyExist = (dbArray, code) => {
  let result = dbArray.filter((db) => {
    return getObjectValueWithKey(code.key, db) === code.value;
  });

  return result.length > 0;
};

export const deepMerge = (store, pathArray, data, index = 0) => {
  return index === pathArray.length - 1
    ? {
        ...store,
        [pathArray[index]]:
          Array.isArray(data) && Array.isArray(store)
            ? [...store[pathArray[index]], ...data]
            : { ...store[pathArray[index]], ...data },
      }
    : {
        ...store,
        [pathArray[index]]: deepMerge(
          typeof store[pathArray[index]] === "undefined"
            ? Array.isArray(data)
              ? []
              : {}
            : store[pathArray[index]],
          pathArray,
          data,
          index + 1
        ),
      };
};
export const getTreeStructure = (data) => {
  const { labels, models } = data;

  if (models.length === 0) {
    return [];
  }

  let nameModelId = "",
    codeModelId = "";
  for (let model in labels) {
    if (labels[model] === "Наименование") {
      nameModelId = model;
    } else if (labels[model] === "Код") {
      codeModelId = model;
    }
  }
  return models.map((item) => ({
    id: item.id,
    name: `${item.model[codeModelId]} - ${
      nameModelId ? item.model[nameModelId] : "Нет наименования"
    }`,
    children: [],
  }));
};
export class TreeNode {
  constructor(props) {
    this.root = props.root;
    this.id = props.id;
    this.name = props.name;
    this.children = props.children || [];
  }

  traverse(callback) {
    function goThrough(node) {
      callback(node);
      node.children.forEach((child) => {
        goThrough(child);
      });
    }
    if (this.root) {
      goThrough(this.root);
    } else {
      goThrough(this);
    }
  }

  hasChild(id) {
    return this.children.reduce((prev, curr) => prev && curr.id === id, false);
  }

  addNode(value, parentId) {
    if (this.search(value.id) !== null) return this;

    const newNode = new TreeNode({
      ...value,
      root: this.root || this,
    });

    if (this.root === null) {
      this.root = newNode;
      return this;
    }

    this.traverse((node) => {
      if (node.id === parentId) {
        node.children.push(newNode);
      }
    });
    return this;
  }

  addChildren(values, parentId) {
    values.map((value) => this.addNode(value, parentId));
    return this;
  }

  removeNode(value) {
    this.traverse((node) => {
      node.children.forEach((childNode, index) => {
        if (childNode.id === value.id) {
          node.children.splice(index, 1);
        }
      });
    });
    return this;
  }

  search(id) {
    let returnNode = null;
    this.traverse((node) => {
      if (node.id === id) {
        returnNode = node;
      }
    });
    return returnNode;
  }

  searchByName(name) {
    let returnNode = null;
    this.traverse((node) => {
      if (node.name.includes(name)) {
        returnNode = node;
      }
    });
    return returnNode;
  }

  displayLeafs(parent) {
    const parentNode =
      typeof parent === "string" ? this.search(parent) : parent;
    if (!parentNode) return [];

    const leafsRet = [];

    if (parentNode.children && !parentNode.children.length) {
      return [parentNode];
    }

    parentNode.children.forEach((child) => leafsRet.push(child));

    return leafsRet.flat();
  }
}
export const generateCsvFile = (headers, items) => {
  let keys = Object.keys(headers),
    separatedString = [
      Object.values(headers).join(";"),
      items.map((row) => keys.map((key) => row[key]).join(";")).join("\n"),
    ].join("\n"),
    blob = new Blob([separatedString], { type: "text/csv;charset=utf-8;" });
  return URL.createObjectURL(blob);
};
