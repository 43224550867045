import moment from "moment";

export const formsData = {
  createIndicator: [
    [
      {
        title: "forms.statisticalIndicator",
        textFields: [
          {
            type: "text",
            label: "createIndicator.basic_concepts",
            name: "basic_concepts",
          },
          {
            type: "text",
            label: "createIndicator.classification",
            name: "classification",
          },
        ],
      },
      {
        title: "forms.administrativeInformation",
        textFields: [
          {
            type: "text",
            label: "createIndicator.Code_stunit",
            name: "Code_stunit",
          },
          {
            type: "text",
            label: "createIndicator.Name_ru_stunit",
            name: "Name_ru_stunit",
          },
          {
            type: "text",
            label: "createIndicator.Name_kg_stunit",
            name: "Name_kg_stunit",
          },
          {
            type: "text",
            label: "createIndicator.Name_eng_stunit",
            name: "Name_eng_stunit",
          },
          {
            type: "text",
            label: "createIndicator.Code_stact",
            name: "Code_stact",
          },
          {
            type: "text",
            label: "createIndicator.Name_stact",
            name: "Name_stact",
          },
          {
            type: "text",
            label: "createIndicator.Matrix_SSI",
            name: "Matrix_SSI",
          },
          {
            type: "text",
            label: "createIndicator.responsible",
            name: "responsible",
          },
          {
            type: "text",
            label: "createIndicator.unit",
            name: "unit",
          },
          {
            type: "text",
            label: "createIndicator.disaggregation",
            name: "disaggregation",
          },
          {
            type: "text",
            label: "createIndicator.period",
            name: "period",
          },
          {
            type: "text",
            label: "createIndicator.timeliness",
            name: "timeliness",
          },
          {
            type: "text",
            label: "createIndicator.period_report",
            name: "period_report",
          },
          {
            type: "text",
            label: "createIndicator.release_rule",
            name: "release_rule",
          },
          {
            type: "text",
            label: "createIndicator.release_end",
            name: "release_end",
          },
          {
            type: "text",
            label: "createIndicator.relevance",
            name: "relevance",
          },
          {
            type: "text",
            label: "createIndicator.international_reporting",
            name: "international_reporting",
          },
          {
            type: "text",
            label: "createIndicator.microdata",
            name: "microdata",
          },
          {
            type: "date",
            label: "createIndicator.date_to",
            name: "date_to",
            minDate: moment(),
          },
          {
            type: "text",
            label: "createIndicator.End_reason",
            name: "End_reason",
          },
        ],
      },
    ],
    [
      {
        title: "forms.basicInformation",
        textFields: [
          {
            type: "text",
            label: "createIndicator.purpose",
            name: "purpose",
          },
          {
            type: "text",
            label: "createIndicator.users",
            name: "users",
          },
          {
            type: "text",
            label: "createIndicator.access",
            name: "access",
          },
          {
            type: "text",
            label: "createIndicator.other_statistics",
            name: "other_statistics",
          },
          {
            type: "text",
            label: "createIndicator.Low",
            name: "Low",
          },
          {
            type: "text",
            label: "createIndicator.Inter_low",
            name: "Inter_low",
          },
          {
            type: "text",
            label: "createIndicator.start_reporting",
            name: "start_reporting",
          },
          {
            type: "text",
            label: "createIndicator.changes_stunit",
            name: "changes_stunit",
          },
          {
            type: "text",
            label: "createIndicator.external_financing",
            name: "external_financing",
          },
          {
            type: "text",
            label: "createIndicator.dissemination",
            name: "dissemination",
          },
        ],
      },
      {
        title: "forms.production",
        textFields: [
          {
            type: "text",
            label: "createIndicator.general_population",
            name: "general_population",
          },
          {
            type: "text",
            label: "createIndicator.type_stunit",
            name: "type_stunit",
          },
          {
            type: "text",
            label: "createIndicator.coverage_economy",
            name: "coverage_economy",
          },
          {
            type: "text",
            label: "createIndicator.data_source",
            name: "data_source",
          },
          {
            type: "text",
            label: "createIndicator.coverage",
            name: "coverage",
          },
          {
            type: "text",
            label: "createIndicator.method",
            name: "method",
          },
          {
            type: "text",
            label: "createIndicator.data_edit",
            name: "data_edit",
          },
          {
            type: "text",
            label: "createIndicator.additional_calculation",
            name: "additional_calculation",
          },
          {
            type: "text",
            label: "createIndicator.seasonal",
            name: "seasonal",
          },
          {
            type: "text",
            label: "createIndicator.confidentiality",
            name: "confidentiality",
          },
          {
            type: "text",
            label: "createIndicator.comparability",
            name: "comparability",
          },

          {
            type: "date",
            label: "createIndicator.date_to",
            name: "date_to",
            minDate: moment(),
          },
        ],
      },
      {
        title: "forms.precisionAndReliability",
        textFields: [
          {
            type: "text",
            label: "createIndicator.error_source",
            name: "error_source",
          },
          {
            type: "text",
            label: "createIndicator.dispersion",
            name: "dispersion",
          },
        ],
      },
      {
        title: "forms.relevantDocumentation",
        textFields: [
          {
            type: "text",
            label: "createIndicator.methodology",
            name: "methodology",
          },
        ],
      },
    ],
    "general.documents_and_links",
    "JoditEditor",
  ],
  createVariable: [
    [
      {
        title: () => "general.variable_type",
        textFields: () => [
          {
            type: "radio",
            variants: [
              {
                value: "conceptual",
                label: "general.conceptual_variables",
              },
              {
                value: "IT",
                label: "general.IT_variables",
              },
            ],
            name: "variable_type",
          },
        ],
      },
    ],
    [
      {
        title: (state) =>
          state.variableType === "IT"
            ? "general.IT_variables"
            : "general.conceptual_variables",
        textFields: (state) =>
          state.variableType === "IT"
            ? [
                {
                  type: "select",
                  label: "createVariable.IT.variableConceptual",
                  name: "variableConceptual",
                  variants: state.conceptualVariables,
                },
                {
                  type: "text",
                  label: "createVariable.IT.code_IT_var",
                  name: "code_IT_var",
                },
                {
                  type: "text",
                  label: "createVariable.IT.name_IT_var",
                  name: "name_IT_var",
                },
                {
                  type: "select",
                  label: "createVariable.IT.unit",
                  name: "unit",
                  variants: state.units,
                },
                {
                  type: "text",
                  label: "createVariable.IT.calculation",
                  name: "calculation",
                },
                {
                  type: "text",
                  label: "createVariable.IT.nsc_department",
                  name: "nsc_department",
                },
                {
                  type: "text",
                  label: "createVariable.IT.person",
                  name: "person",
                },
                {
                  type: "text",
                  label: "createVariable.IT.Classification_link",
                  name: "Classification_link",
                },
                {
                  type: "text",
                  label: "createVariable.IT.value_name",
                  name: "value_name",
                },
                {
                  type: "text",
                  label: "createVariable.IT.person_name",
                  name: "person_name",
                },
                {
                  type: "date",
                  label: "createVariable.IT.date_from_IT_var",
                  name: "date_from_IT_var",
                  minDate: moment(),
                },
                {
                  type: "date",
                  label: "createVariable.IT.date_to_IT_var",
                  name: "date_to_IT_var",
                  minDate: moment(),
                },
                {
                  type: "text",
                  label: "createVariable.IT.End_reason",
                  name: "End_reason",
                },
                {
                  type: "text",
                  label: "createVariable.IT.change_person_name",
                  name: "change_person_name",
                },
              ]
            : [
                {
                  type: "select",
                  label: "createVariable.Conceptual.Code_stact",
                  name: "Code_stact",
                  variants: state.codeStact,
                },
                {
                  type: "select",
                  label: "createVariable.Conceptual.Name_stact",
                  name: "Name_stact",
                  variants: state.nameStact,
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.code_var",
                  name: "code_var",
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.Name_var",
                  name: "Name_var",
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.Short_name_var",
                  name: "Short_name_var",
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.definition_ru",
                  name: "definition_ru",
                },
                {
                  type: "date",
                  label: "createVariable.Conceptual.date_from",
                  name: "date_from",
                  minDate: moment(),
                },
                {
                  type: "date",
                  label: "createVariable.Conceptual.date_to",
                  name: "date_to",
                  minDate: moment(),
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.End_reason",
                  name: "End_reason",
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.internal_user_info",
                  name: "internal_user_info",
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.external_user_info",
                  name: "external_user_info",
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.source",
                  name: "source",
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.confidentiality",
                  name: "confidentiality",
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.person",
                  name: "person",
                },
                {
                  type: "date",
                  label: "createVariable.Conceptual.date_var",
                  name: "date_var",
                  minDate: moment(),
                },
                {
                  type: "text",
                  label: "createVariable.Conceptual.Last_change_person",
                  name: "Last_change_person",
                },
                {
                  type: "date",
                  label: "createVariable.Conceptual.Last_change_date",
                  name: "Last_change_date",
                },
              ],
      },
    ],
    "general.documents_and_links",
  ],
  createClassifier: [
    [
      {
        title: "general.classifierType",
        textFields: [
          {
            type: "radio",
            variants: [
              // {
              //   value: process.env.REACT_APP_FACETED,
              //   label: "general.faceted",
              // },
              {
                value: process.env.REACT_APP_FLAT,
                label: "general.flat",
              },
              {
                value: process.env.REACT_APP_HIERARCHICAL,
                label: "general.hierarchical",
              },
              {
                value: process.env.REACT_APP_COMPLICATED,
                label: "general.complicated",
              },
            ],
            name: "classifier_type",
          },
        ],
      },
    ],
    [
      {
        title: "forms.code",
        textFields: [
          {
            type: "text",
            label: "createClassifier.Reg_Code",
            name: "Reg_Code",
          },
        ],
      },
      {
        title: "general.Name",
        textFields: [
          {
            type: "text",
            label: "createClassifier.Name_ru",
            name: "Name_ru",
          },
          {
            type: "text",
            label: "createClassifier.Sname_ru",
            name: "Sname_ru",
          },
        ],
      },
      {
        title: "forms.description",
        textFields: [
          {
            type: "text",
            label: "createClassifier.description_ru",
            name: "description_ru",
          },
          {
            type: "text",
            label: "createClassifier.link",
            name: "link",
          },
        ],
      },
    ],
    [
      {
        title: "",
        textFields: [
          {
            type: "text",
            label: "createClassifier.object",
            name: "object",
          },
          {
            type: "text",
            label: "createClassifier.language",
            name: "language",
          },
          {
            type: "text",
            label: "createClassifier.developer",
            name: "developer",
          },
          {
            type: "text",
            label: "createClassifier.analog_name",
            name: "analog_name",
          },
        ],
      },
    ],
    [
      {
        title: "",
        textFields: [
          {
            type: "text",
            label: "createClassifier.Last_change",
            name: "Last_change",
          },
          {
            type: "text",
            label: "createClassifier.Doc_use",
            name: "Doc_use",
          },
          {
            type: "text",
            label: "createClassifier.Previous_versions",
            name: "Previous_versions",
          },
          {
            type: "date",
            label: "createClassifier.implementation_date",
            name: "implementation_date",
            minDate: moment(),
          },
          {
            type: "date",
            label: "createClassifier.Date_end",
            name: "Date_end",
            minDate: moment(),
          },
          {
            type: "text",
            label: "createClassifier.Contact_person",
            name: "Contact_person",
          },
        ],
      },
    ],
    "general.documents_and_links",
  ],
  createDefinition: [
    [
      {
        title: "general.definition",
        textFields: [
          {
            type: "text",
            label: "createDefinition.id",
            name: "id",
          },
          {
            type: "text",
            label: "createDefinition.code_stact",
            name: "code_stact",
          },
          {
            type: "text",
            label: "createDefinition.name_stact",
            name: "name_stact",
          },
          {
            type: "text",
            label: "createDefinition.name_ru",
            name: "name_ru",
          },
          {
            type: "text",
            label: "createDefinition.definition_ru",
            name: "definition_ru",
          },
          {
            type: "text",
            label: "createDefinition.source_name",
            name: "source_name",
          },
          {
            type: "text",
            label: "createDefinition.source_link",
            name: "source_link",
          },
        ],
      },
    ],
    "general.documents_and_links",
  ],
  createDirectory: [
    [
      {
        title: () => "createDirectory.directoryType",
        textFields: () => [
          {
            type: "radio",
            variants: [
              {
                label: "general.catalog",
                value: "independent",
              },
              {
                label: "general.catalogFromClassifier",
                value: "fromClassifier",
              },
            ],
            name: "directoryType",
          },
        ],
      },
    ],
    [
      {
        title: () => "forms.code",
        textFields: () => [
          {
            type: "text",
            label: "createDirectory.Code",
            name: "Code",
          },
        ],
      },
      {
        title: () => "general.Name",
        textFields: () => [
          {
            type: "text",
            label: "createDirectory.name_ru",
            name: "name_ru",
          },
          {
            type: "text",
            label: "createDirectory.Shortname_ru",
            name: "Shortname_ru",
          },
        ],
      },
      {
        title: () => "forms.description",
        textFields: () => [
          {
            type: "text",
            label: "createDirectory.description_ru",
            name: "description_ru",
          },
        ],
      },
    ],
    [
      {
        title: () => "",
        textFields: () => [
          {
            type: "text",
            label: "createDirectory.language",
            name: "language",
          },
          {
            type: "text",
            label: "createDirectory.developer",
            name: "developer",
          },
          {
            type: "text",
            label: "createDirectory.Doc_use",
            name: "Doc_use",
          },
          {
            type: "text",
            label: "createDirectory.Last_change",
            name: "Last_change",
          },
          {
            type: "date",
            label: "createDirectory.Doc_use_date",
            name: "Doc_use_date",
          },
          {
            type: "date",
            label: "createDirectory.Doc_end_date",
            name: "Doc_end_date",
          },
          {
            type: "text",
            label: "createDirectory.Previous_versions",
            name: "Previous_versions",
          },
          {
            type: "text",
            label: "createDirectory.Responsible_Dep",
            name: "Responsible_Dep",
          },
        ],
      },
      {
        title: () => "general.links",
        textFields: () => [
          {
            type: "text",
            label: "createDirectory.CodeListDoc_Link",
            name: "CodeListDoc_Link",
          },
        ],
      },
      {
        title: ({ isFromClassifier }) =>
          isFromClassifier ? "errors.chooseClassifier" : "",
        textFields: ({ isFromClassifier, classifiersList }) => {
          let variants = [];
          classifiersList.forEach((classifier) =>
            variants.push({ label: classifier.name, value: classifier.id })
          );
          return isFromClassifier
            ? [
                {
                  type: "select",
                  name: "classifierId",
                  label: "errors.chooseClassifier",
                  variants,
                },
                {
                  type: "text",
                  label: "errors.enterRequest",
                  name: "classifierQuery",
                },
              ]
            : [];
        },
      },
    ],
    "general.documents_and_links",
  ],
};
