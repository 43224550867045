import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

export const SearchTextInput = withStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    borderRadius: 4,
    transition: "150ms linear width",
    "& .MuiInputLabel-root": {
      top: -4,
      color: "#1C5C91",
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: 14,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      top: 2,
    },
    "& .MuiOutlinedInput-root": {
      height: 45,
      "& fieldset": {
        borderRadius: 4,
        borderColor: "#757575 ",
        borderWidth: 0.5,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1C5C91",
      },
      "& input": {
        padding: " 12px",
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: 14,
        color: "#1C5C91",
      },
    },
  },
}))(TextField);
