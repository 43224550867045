import { Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

export function TabsComponent({ tab, setTab, buttons }) {
  const classes = useStyles();
  return (
    <Tabs
      value={tab}
      onChange={(_, newState) => setTab(newState)}
      aria-label='basic tabs example'
      classes={{ root: classes.tabs }}
    >
      {buttons.map((btn) => (
        <Tab key={btn} label={btn} />
      ))}
    </Tabs>
  );
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    maxHeight: 40,
    "& .MuiTab-root": {
      padding: "2px 0px",
      marginRight: 32,
      color: "#757575",
      textAlign: "start",
    },
    "& .Mui-selected": {
      color: "#1C5C91 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#1C5C91",
      height: 3,
    },
  },
}));
