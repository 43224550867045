import React from "react";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  DateRangePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/lab";

import AdvansedSelect from "./AdvancedSelect";
import {
  ValidatedTextInput,
  StandardTextInput,
} from "shared/StandardTextInput";

export default function ParseDataAndReturnForm({
  data,
  disableEverithing,
  columns = 2,
  radioGroupValue = () => undefined,
  changeRadioGroup = () => undefined,
  variationKey,
  submitHandler,
  initialValues,
  actionComponent,
  validationSchema,
  enableReinitialize,
  datePickerValue = () => undefined,
  changeDatePicker = () => undefined,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { parentWordsDictionary } = useSelector((store) => store);

  const getInput = (input, index, formik) => {
    switch (input.type) {
      case "radio": {
        return (
          <RadioGroup
            key={input.name}
            name={input.name}
            value={radioGroupValue(input.name)}
            onChange={(e) => changeRadioGroup(e.target)}
            style={{
              marginBottom: 40,
            }}
            disabled={disableEverithing}>
            {input.variants.map((variantItem) => (
              <FormControlLabel
                key={variantItem.label}
                value={variantItem.value}
                control={<Radio style={{ color: "#1C5C91" }} />}
                label={t(variantItem.label)}
              />
            ))}
          </RadioGroup>
        );
      }
      case "select": {
        let isStactSelect =
            input.name === "Code_stact" || input.name === "Name_stact",
          codeStact = input.name === "Code_stact",
          units = input.name === "unit",
          select = (
            <ValidatedTextInput
              key={index}
              name={input.name}
              label={t(input.label)}
              variant="standard"
              value={formik.values[input.name]}
              onChange={formik.handleChange}
              select
              style={{
                width: `calc(${100 / columns}% - 20px)`,
                marginBottom: 40,
              }}
              SelectProps={{
                MenuProps: { className: classes.menu },
                renderValue: codeStact
                  ? (value, ...rest) => value.split("-")[0]
                  : undefined,
              }}
              disabled={disableEverithing}>
              {input.variants.map((variant) => (
                <MenuItem key={variant.value} value={variant.value}>
                  {variant.label}
                </MenuItem>
              ))}
            </ValidatedTextInput>
          );
        return parentWordsDictionary[String(input.label).toLowerCase()] ||
          units ||
          isStactSelect ? (
          <AdvansedSelect
            key={index}
            input={input}
            formik={formik}
            style={{
              width: `calc(${100 / columns}% - 20px)`,
              marginBottom: 40,
            }}
            disabled={disableEverithing}
          />
        ) : (
          select
        );
      }
      case "date": {
        let props = {},
          dateValue = datePickerValue(input.name);

        if (Boolean(input.maxDate)) {
          props.maxDate = input.maxDate;
        }
        if (Boolean(input.minDate)) {
          props.minDate = input.minDate;
        }
        return (
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            locale={i18n.language}
            key={index}>
            <DesktopDatePicker
              {...props}
              value={Boolean(dateValue) ? dateValue : null}
              onChange={(newDate) => {
                changeDatePicker(input.name, newDate);
              }}
              name={input.name}
              label={t(input.label)}
              inputFormat="DD.MM.yyyy"
              mask="__.__.____"
              disabled={disableEverithing}
              renderInput={(params) => (
                <StandardTextInput
                  {...params}
                  error={false}
                  variant="standard"
                  style={{
                    width: `calc(${100 / columns}% - 20px)`,
                    marginBottom: 40,
                  }}
                />
              )}
              OpenPickerButtonProps={{ style: { color: "#1C5C91" } }}
            />
          </LocalizationProvider>
        );
      }
      case "date_range": {
        let props = {},
          dateValue = datePickerValue(input.name);

        if (Boolean(input.maxDate)) {
          props.maxDate = input.maxDate;
        }
        if (Boolean(input.minDate)) {
          props.minDate = input.minDate;
        }
        return (
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            locale={i18n.language}
            key={index}>
            <DateRangePicker
              value={dateValue}
              onChange={(range) => changeDatePicker(input.name, range)}
              inputFormat="DD.MM.yyyy"
              mask="__.__.____"
              name={input.name}
              label={t(input.label)}
              startText={t(input.label)}
              componentsProps={{ style: { width: "100%" } }}
              renderInput={(startProps, endProps) => (
                <StandardTextInput
                  {...startProps}
                  error={false}
                  variant="standard"
                  fullWidth
                  style={{
                    marginBottom: 40,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        );
      }
      default: {
        let isClassifierQuery = input.name === "classifierQuery",
          maxLength = Boolean(input.maxLength) ? parseInt(input.maxlength) : 0,
          textField = (
            <ValidatedTextInput
              key={index}
              type={input.type}
              name={input.name}
              label={t(input.label)}
              placeholder={input.placeholder}
              variant={isClassifierQuery ? "outlined" : "standard"}
              value={formik.values[input.name]}
              onChange={(event) => {
                if (
                  Boolean(maxLength) &&
                  event.target.value.length >= maxLength
                ) {
                  event.target.value = event.target.value.substr(0, maxLength);
                }
                formik.handleChange(event);
              }}
              multiline={isClassifierQuery || input.multiline}
              disabled={disableEverithing}
              style={{
                width: `calc(${100 / columns}% - 20px)`,
                marginBottom: input.name === "classifierQuery" ? 10 : 40,
              }}
              rows={isClassifierQuery ? 4 : 0}
            />
          );
        // if type of input text
        return input.name === "classifierQuery" ? (
          <div
            className="flex_box"
            style={{ width: "100%", justifyContent: "flex-start" }}>
            {textField}
          </div>
        ) : (
          textField
        );
      }
    }
  };
  return (
    <Formik
      onSubmit={submitHandler}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={Boolean(enableReinitialize)}>
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          {data.length > 0 &&
            data.map((item, i, array) => {
              // check if item is metadata section or field
              return Boolean(item.textFields) ? (
                <span key={i}>
                  {item.title && (
                    <Typography
                      variant="body1"
                      className={classes.stepTitle}
                      style={{
                        marginBottom: 40,
                      }}>
                      {variationKey
                        ? t(item.title(variationKey))
                        : t(item.title)}
                    </Typography>
                  )}
                  <div className="flex_box" style={{ flexWrap: "wrap" }}>
                    {variationKey
                      ? item
                          .textFields(variationKey)
                          .map((item, index, array) =>
                            getInput(item, index, formik, array.length)
                          )
                      : item.textFields.map((item, index, array) =>
                          getInput(item, index, formik, array.length)
                        )}
                  </div>
                </span>
              ) : (
                getInput(item, i, formik, array.length)
              );
            })}

          {actionComponent}
        </Form>
      )}
    </Formik>
  );
}

const useStyles = makeStyles((theme) => ({
  stepTitle: {
    fontFamily: "Open Sans !important",
    fontSize: "22px !important",
    fontWeight: "600 !important",
    marginBottom: "20px !important",
  },
  menu: {
    "& .MuiPaper-root": {
      maxWidth: 500,
    },
    "& .MuiPaper-root li": {
      whiteSpace: "normal",
    },
  },
}));
