import React, { useState } from "react";
import { List, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import { Button, Popover, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import searchIcon from "assets/SVG/bx-search.svg";
import dropDownIcon from "assets/SVG/ArrowDropDown.svg";
import { SearchTextInput, ValidatedTextInput } from "shared";

// In this example, average cell height is assumed to be about 50px.
// This value will be used for the initial `Grid` layout.
// Width is not dynamic.
const cache = new CellMeasurerCache({
  defaultHeight: 50,
  fixedWidth: true,
});

export default function AdvansedSelect({ input, formik, style, disabled }) {
  const { t } = useTranslation();
  const textInput = React.useRef();
  const classes = useStyles({
    disabled,
    menuWidth: textInput.current ? textInput.current.offsetWidth : 552,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [listItems, setListItems] = useState([]);
  const [selectedItemLabel, setSelectedItemLabel] = useState("");
  let isStactSelect =
      input.name === "Code_stact" || input.name === "Name_stact",
    codeStact = input.name === "Code_stact";

  React.useEffect(() => {
    let parentCode = formik.values[input.name];
    setListItems(input.variants);
    if (parentCode) {
      let selectdItem = input.variants.filter(
        (item) => item.value === parentCode
      );
      setSelectedItemLabel(
        Boolean(selectdItem.length)
          ? codeStact
            ? selectdItem[0].label.split("-")[0]
            : selectdItem[0].label
          : ""
      );
    }
  }, [input, formik, codeStact]);

  const onSearch = (text) => {
    setSearchText(text);
    if (Boolean(text.length)) {
      setListItems((state) =>
        state.filter((item) => {
          return item.label.match(new RegExp(`^${text}`, "gi"));
        })
      );
    } else {
      setListItems(input.variants);
    }
  };
  const RenderItem = ({ index, isScrolling, key, parent, style }) => {
    let item = listItems[index];
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}>
        {({ registerChild }) => (
          <Button
            fullWidth
            ref={registerChild}
            key={key}
            style={style}
            onClick={() => {
              if (isStactSelect) {
                formik.handleChange({
                  target: {
                    name: "Code_stact",
                    value: codeStact ? item.value : item.code,
                  },
                });
                formik.handleChange({
                  target: {
                    name: "Name_stact",
                    value: codeStact ? item.name : item.value,
                  },
                });
              } else {
                formik.handleChange({
                  target: { name: input.name, value: item.value },
                });
              }
              setSelectedItemLabel(
                codeStact ? item.label.split("-")[0] : item.label
              );
              setOpenMenu(false);
            }}>
            <Typography
              variant="body1"
              style={{
                width: "100%",
                fontWeight: 400,
                color: "#333333",
                textAlign: "start",
              }}>
              {item.label}
            </Typography>
          </Button>
        )}
      </CellMeasurer>
    );
  };
  return (
    <div style={style}>
      <ValidatedTextInput
        ref={textInput}
        type={input.type}
        name={input.name}
        label={t(input.label) || input.label}
        placeholder={t(input.placeholder) || input.placeholder}
        variant="standard"
        value={selectedItemLabel}
        onClick={(event) => {
          if (!disabled) {
            setAnchorEl(event.currentTarget);
            setOpenMenu(true);
          }
        }}
        InputProps={
          disabled
            ? undefined
            : { endAdornment: <img src={dropDownIcon} alt="" /> }
        }
        disabled
        className={classes.textInput}
      />
      <Popover
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{ paper: classes.menuPaper }}>
        <SearchTextInput
          value={searchText}
          onChange={(e) => onSearch(e.target.value)}
          placeholder={t("general.name_search")}
          style={{ width: "100%", marginBottom: 16 }}
          InputProps={{
            endAdornment: <img src={searchIcon} alt="search icon" />,
          }}
        />

        <List
          width={textInput.current ? textInput.current.offsetWidth : 552}
          height={400}
          rowCount={listItems.length}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowRenderer={RenderItem}
        />
      </Popover>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  menuPaper: ({ menuWidth }) => ({
    height: 400,
    padding: 16,
    width: "100%",
    borderRadius: 4,
    overflow: "hidden",
    maxWidth: menuWidth,
  }),
  textInput: ({ disabled }) => ({
    "& .MuiInputLabel-root": {
      color: disabled ? "#000" : "#1C5C91",
      WebkitTextFillColor: disabled ? "#000" : "#1C5C91",
    },
    "& .MuiInput-root": {
      cursor: disabled ? "default" : "pointer",
    },
    "& .MuiInput-root:before": {
      borderBottomStyle: disabled ? "dotted" : "solid",
    },
    "& input": {
      cursor: disabled ? "default" : "pointer",
    },
  }),
}));
