import { get_inn_info, organizationCheck } from "api";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PrimaryButton } from "shared/PrimaryButton";
import { ValidatedTextInput } from "shared/StandardTextInput";
import * as Yup from "yup";

export const Organization = ({ organization, createOrUpdate, isCreate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [exception, setException] = useState({
    innEmployee: "",
  });
  const organizationInitilaValues = {
    inn: Boolean(organization.inn) ? organization.inn : "",
    name: Boolean(organization.name) ? organization.name : "",
  };

  const organizationInnChange = React.useCallback(
    (value) => {
      if (value.length === 14) {
        dispatch(
          get_inn_info(value, (data) => {
            if (data) {
              setException({
                ...exception,
                innOrganisation: data.name,
              });
              dispatch(organizationCheck(value));
            } else {
              setException({
                ...exception,
                innOrganisation: t("errors.informationNotFound"),
              });
            }
          })
        );
      }
      if (Boolean(exception.innOrganisation)) {
        setException({ ...exception, innOrganisation: "" });
      }
    },
    [exception, dispatch, t]
  );
  return (
    <Formik
      validationSchema={Yup.object({
        inn: Yup.string()
          .max(14, t("errors.enterYourINNCorrectly"))
          .required(t("errors.fillTheInput")),
        name: Yup.string().required(t("errors.fillTheInput")),
      })}
      onSubmit={(values) => {
        if (isCreate) {
          createOrUpdate(values);
        } else {
          createOrUpdate({ id: organization.id, ...values }, organization.id);
        }
      }}
      initialValues={organizationInitilaValues}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <ValidatedTextInput
            style={{
              marginBottom: 40,
            }}
            value={formik.values["inn"]}
            onChange={(e) => {
              if (e.target.value.length >= 14) {
                e.target.value = e.target.value.substr(0, 14);
              }
              formik.handleChange(e);
              organizationInnChange(e.target.value);
            }}
            onBlur={formik.handleBlur}
            variant='standard'
            name='inn'
            label={t("forms.organizationINN")}
            fullWidth
            exception={exception["innEmployee"]}
          />
          <ValidatedTextInput
            style={{
              marginBottom: 40,
            }}
            value={formik.values["name"]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant='standard'
            name='name'
            label={t("general.Name")}
            fullWidth
            multiline
          />
          <div
            className='flex_box'
            style={{ width: "100%", justifyContent: "center" }}
          >
            <PrimaryButton
              style={{ width: "50%" }}
              variant='contained'
              type='submit'
              disabled={
                !Boolean(
                  organizationInitilaValues.inn !== formik.values["inn"] ||
                    organizationInitilaValues.name !== formik.values["name"]
                )
              }
            >
              {isCreate ? t("general.create") : t("general.save")}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
