import { Dialog, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import logo from "assets/SVG/Logo_Stat.svg";
import close from "assets/SVG/Close.svg";
import { Form, Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { ValidatedTextInput } from "shared/StandardTextInput";
import { PrimaryButton } from "shared/PrimaryButton";
import * as Yup from "yup";
import {
  employeeCheck,
  employeeConfirm,
  get_inn_info,
  organizationCheck,
} from "api";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function AuthorizationDialog({ dialog, handleClose }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSomeError, setError] = useState({
    innOrganisation: false,
    innEmployee: false,
    pin: false,
  });
  const [exception, setException] = useState({
    innOrganisation: "",
    innEmployee: "",
    pin: "",
  });

  const validate = Yup.object({
    innOrganisation: Yup.string()
      .max(14, t("errors.enterYourINNCorrectly"))
      .required(t("errors.fillTheInput")),
    innEmployee: Yup.string()
      .max(14, t("errors.enterYourINNCorrectly"))
      .required(t("errors.fillTheInput")),
    pin: Yup.string()
      .max(4, t("errors.passwordIsIncorrect"))
      .required(t("errors.fillTheInput")),
  });

  const organizationInnChange = (value) => {
    if (value.length === 14) {
      dispatch(
        get_inn_info(value, (data) => {
          if (data) {
            setException({
              ...exception,
              innOrganisation: data.name,
            });
            dispatch(organizationCheck(value));
          } else {
            setError({ ...isSomeError, innOrganisation: true });
            setException({
              ...exception,
              innOrganisation: t("errors.informationNotFound"),
            });
          }
        })
      );
    }
    if (isSomeError.innOrganisation) {
      setError({ ...isSomeError, innOrganisation: false });
    }
    if (Boolean(exception.innOrganisation)) {
      setException({ ...exception, innOrganisation: "" });
    }
  };
  const employeeInnChange = (value) => {
    if (value.length === 14) {
      dispatch(
        get_inn_info(value, (data) => {
          if (data) {
            setException({
              ...exception,
              innEmployee: data.name,
            });
            dispatch(employeeCheck(value));
          } else {
            setError({ ...isSomeError, innEmployee: true });
            setException({
              ...exception,
              innEmployee: t("errors.informationNotFound"),
            });
          }
        })
      );
    }
    if (isSomeError.innEmployee) {
      setError({ ...isSomeError, innEmployee: false });
    }
    if (Boolean(exception.innEmployee)) {
      setException({ ...exception, innEmployee: "" });
    }
  };
  const submitHandler = (values) => {
    refreshState();
    dispatch(
      employeeConfirm(values, () => {
        dialog.onSignIn();
      })
    );
  };
  const refreshState = () => {
    setException({
      innOrganisation: "",
      innEmployee: "",
      pin: "",
    });
    handleClose();
  };
  return (
    <Dialog
      open={dialog.open}
      onClose={refreshState}
      classes={{ paper: classes.content }}
      scroll='body'
    >
      <DialogContent dividers={false}>
        <div
          className='flex_box'
          style={{ position: "relative", justifyContent: "flex-end" }}
        >
          <div className='flex_box unsplash'>
            <div
              style={{ textAlign: "center", position: "relative", zIndex: 1 }}
            >
              <img
                src={logo}
                style={{ width: 100, height: 100, borderRadius: "50%" }}
                alt='logo'
              />
              <Typography variant='body1' className={classes.title}>
                {t("general.header_title")}
              </Typography>
            </div>
            <span className='blue_cover' />
          </div>

          <div
            style={{
              width: "calc(100% - 366px)",
              position: "relative",
              padding: "32px 40px",
            }}
          >
            <Formik
              onSubmit={submitHandler}
              initialValues={{
                innOrganisation: "",
                innEmployee: "",
                pin: "",
              }}
              validationSchema={validate}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <Typography variant='h3' style={{ marginBottom: 25 }}>
                    {t("forms.loginToTheSystem")}
                  </Typography>

                  <Typography variant='body2' className={classes.subtitle}>
                    {t("forms.organizationINN")}
                  </Typography>
                  <div className='flex_box_vertical' style={{ minHeight: 90 }}>
                    <ValidatedTextInput
                      variant='outlined'
                      onBlur={formik.handleBlur}
                      value={formik.values.innOrganisation}
                      onChange={(e) => {
                        if (e.target.value.length >= 14) {
                          e.target.value = e.target.value.substr(0, 14);
                        }
                        formik.handleChange(e);
                        organizationInnChange(e.target.value);
                      }}
                      className={classes.textField}
                      margin='dense'
                      name='innOrganisation'
                    />
                    <Typography
                      variant='caption'
                      component='div'
                      style={{ marginTop: 8, color: "#757575" }}
                    >
                      {exception["innOrganisation"]}
                    </Typography>
                  </div>

                  <Typography
                    variant='body2'
                    className={classes.subtitle}
                    style={{ marginTop: 20 }}
                  >
                    {t("forms.employeeINN")}
                  </Typography>
                  <div className='flex_box_vertical' style={{ minHeight: 90 }}>
                    <ValidatedTextInput
                      variant='outlined'
                      onBlur={formik.handleBlur}
                      value={formik.values.innEmployee}
                      onChange={(e) => {
                        if (e.target.value.length >= 14) {
                          e.target.value = e.target.value.substr(0, 14);
                        }
                        formik.handleChange(e);
                        employeeInnChange(e.target.value);
                      }}
                      className={classes.textField}
                      margin='dense'
                      name='innEmployee'
                    />
                    <Typography
                      variant='caption'
                      component='div'
                      style={{ marginTop: 8, color: "#757575" }}
                    >
                      {exception["innEmployee"]}
                    </Typography>
                  </div>

                  <Typography
                    variant='body2'
                    className={classes.subtitle}
                    style={{ marginTop: 20 }}
                  >
                    {t("forms.password")}
                  </Typography>
                  <ValidatedTextInput
                    variant='outlined'
                    onBlur={formik.handleBlur}
                    value={formik.values.pin}
                    onChange={(event) => {
                      if (event.target.value.length >= 4) {
                        event.target.value = event.target.value.substr(0, 4);
                      }
                      formik.handleChange(event);
                    }}
                    className={classes.textField}
                    margin='dense'
                    name='pin'
                    autoComplete='off'
                    type='password'
                  />

                  <PrimaryButton
                    variant='contained'
                    type='submit'
                    style={{ marginTop: 40 }}
                    fullWidth
                  >
                    {t("general.login")}
                  </PrimaryButton>
                </Form>
              )}
            </Formik>
            <img
              className={classes.closeIcon}
              src={close}
              alt='close_icon'
              onClick={refreshState}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto Condensed",
    fontWeight: "bold",
    color: "#FFF",
    textAlign: "center",
    padding: "0px 10px",
  },
  subtitle: {
    fontWeight: "bold !important",
  },
  content: {
    minWidth: 776,
    "& .MuiDialogContent-root": {
      padding: 0,
    },
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    top: 21,
    right: 21,
  },
  textField: {
    height: 44,
  },
}));
