import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";

export const PrimaryButton = withStyles({
  root: {
    height: 45,
    boxShadow: "none",
  },
  containedPrimary: {
    backgroundColor: "#1C5C91 !important",
    "&:hover": {
      backgroundColor: "#2472B3 !important",
      boxShadow: "none !important",
    },
  },
  outlinedPrimary: {
    color: "#1C5C91 !important",
    borderColor: "#1C5C91 !important",
  },
  disabled: {
    color: "#757575 !important",
    backgroundColor: "#F5F8FF !important",
    border: "1px solid #757575 !important",
  },
})(Button);
