import { applyMiddleware, compose, createStore } from "redux";

import { initialState } from "./initialState";
import thunk from "redux-thunk";
import { deepMerge } from "api";

const appReducer = (store = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_STORE_DATA:
      return { ...store, ...payload };
    case UPDATE_STORE_DATA:
      let pathArray = payload.key.split(".");
      return deepMerge(store, pathArray, payload.data);
    default:
      return store;
  }
};

export const CREATE_STORE_DATA = "CREATE_STORE_DATA";
export const UPDATE_STORE_DATA = "UPDATE_STORE_DATA";

export const createStoreData = (data) => ({
  type: CREATE_STORE_DATA,
  payload: data,
});
export const updateStoreData = (data) => ({
  type: UPDATE_STORE_DATA,
  payload: data,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
);
export const store = createStore(appReducer, enhancer);
