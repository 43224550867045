import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "shared/PrimaryButton";

export default function ConfirmRemoveDialog({
  open,
  closeHandler,
  confirmHandler,
  logout,
}) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={closeHandler}>
      <DialogTitle>
        {t("questions.are_you_sure", {
          verb: logout
            ? t("general.logout").toLowerCase()
            : t("general.delete").toLowerCase(),
        })}
      </DialogTitle>
      <DialogActions>
        <PrimaryButton onClick={closeHandler}>
          {t("general.cancell")}
        </PrimaryButton>
        <PrimaryButton
          onClick={() => {
            confirmHandler();
            closeHandler();
          }}
          style={{ color: "#ED1528" }}
          autoFocus>
          {logout ? t("general.logout") : t("general.delete")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
