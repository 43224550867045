import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import code from "assets/SVG/bx-code.svg";
import { SearchTextInput } from "../shared";
import searchIcon from "assets/SVG/bx-search.svg";
import administration from "assets/SVG/bx-cog.svg";
import bookContent from "assets/SVG/bx-book-content.svg";
import pieChart from "assets/SVG/bx-pie-chart-alt-2.svg";
import { filterBySearch, searchDataBaseByName } from "api";
// import bookBookmark from "assets/SVG/bx-book-bookmark.svg";

const focusedSearchInput = 300;

export default function Main() {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const w660 = useMediaQuery(theme.breakpoints.down(660));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { employee, dbList } = useSelector((store) => store);

  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  React.useEffect(() => {
    if (Array.isArray(dbList) && !Boolean(dbList.length)) {
      // Получить список всех таблиц с короткой информацией
      dispatch(searchDataBaseByName({ formName: "", formTypes: [] }));
    }
  }, [dbList, dispatch]);

  const searchFilter = (text) => {
    setSearch(text);
    setSearchResult(filterBySearch(dbList, text));
  };

  return (
    <>
      <Container>
        <section className="search flex_box" style={{ flexWrap: "wrap" }}>
          <Typography variant="h1">{t("general.metadata_title")}</Typography>
          <div style={{ position: "relative" }}>
            <SearchTextInput
              value={search}
              onChange={(e) => searchFilter(e.target.value)}
              placeholder={t("general.name_search")}
              style={{
                width: focusedSearchInput,
                marginTop: w660 ? 25 : 0,
              }}
              InputProps={{
                endAdornment: <img src={searchIcon} alt="search icon" />,
              }}
            />
            {searchResult.length > 0 && search.length > 0 && (
              <Paper className={classes.searchResultList}>
                {searchResult.length > 0 ? (
                  searchResult.map((db, index) => (
                    <NavLink
                      to={db.path}
                      key={db.path}
                      style={{ color: "#1C5C91" }}>
                      <Typography
                        variant="body1"
                        style={{
                          padding: "8px 24px",
                          lineHeight: "150%",
                          borderBottom:
                            index + 1 !== searchResult.length
                              ? "1px solid #757575"
                              : "none",
                        }}>
                        {db.name}
                      </Typography>
                    </NavLink>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "red",
                    }}>
                    {t("nothingFoundByName", { search_text: `"${search}"` })}
                  </Typography>
                )}
              </Paper>
            )}
          </div>
        </section>
      </Container>

      <nav className="top_nav">
        <Container>
          <Grid container spacing={3}>
            {navItems(t).map((nav) =>
              (nav.path === "/administration" &&
                (Boolean(employee.isAdmin) ||
                  Boolean(employee.isSuperAdmin))) ||
              nav.path !== "/administration" ? (
                <Grid item xs={12} sm={6} lg={4} key={nav.path}>
                  <div
                    onClick={() => navigate(nav.path)}
                    className="flex_box nav_item">
                    <img src={nav.icon} alt={nav.title} />
                    <div style={{ width: `calc(100% - 60px)` }}>
                      <Typography
                        variant="h4"
                        component="span"
                        style={{
                          borderBottom: "2px solid #1C5C91",
                          paddingBottom: 4,
                        }}>
                        {nav.title}
                      </Typography>
                      <br />
                      <Typography variant="body1" style={{ marginTop: 8 }}>
                        {nav.subtitle}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              ) : null
            )}
          </Grid>
        </Container>
      </nav>

      <section className="text_section">
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {t("general.metadata_title")}
              </Typography>
              <Typography variant="body1">
                {t("main_page.main_paraph_1")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">
                {t("main_page.main_paraph_2")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  searchResultList: {
    width: focusedSearchInput,
    maxHeight: "50vh",
    overflowY: "auto",
    padding: "16px 0px",
    position: "absolute",
    top: 60,
    left: 0,
  },
}));

const navItems = (t) => [
  {
    title: `${t("general.classifiersAndCodeLists")}`,
    icon: bookContent,
    path: "/classifiers&codelists",
  },
  {
    title: t("general.variablesAndDefinitions"),
    icon: code,
    path: "/variables&definitions",
  },
  {
    title: t("general.abount_statistic"),
    icon: pieChart,
    path: "/about-statistic",
  },
  {
    title: t("general.administration"),
    icon: administration,
    path: "/administration",
  },
];
