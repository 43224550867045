import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: process.env.REACT_APP_LANGUAGE,
  resources: {
    en: {
      translations: require("./en_translations.json"),
    },
    ru: {
      translations: require("./ru_translations.json"),
    },
    kg: {
      translations: require("./kg_translations.json"),
    },
  },
  ns: ["translations"],
  defaultNs: "translations",
});

i18n.languages = ["en", "ru", "kg"];

export default i18n;

export const kgAlphabet = [
  "а",
  "б",
  "в",
  "г",
  "д",
  "е",
  "ё",
  "ж",
  "з",
  "и",
  "й",
  "к",
  "л",
  "м",
  "н",
  "ң",
  "о",
  "ө",
  "п",
  "р",
  "с",
  "т",
  "у",
  "ү",
  "ф",
  "х",
  "ц",
  "ч",
  "ш",
  "щ",
  "ъ",
  "ы",
  "ь",
  "э",
  "ю",
  "я",
];

export const momentLocales = {
  ru: {
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    monthsShort: "Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек".split("_"),
    monthsParseExact: true,
    weekdays:
      "Понедельник_Вторник_Среда_Четверг_Пятница_Суббота_Воскресенье".split(
        "_"
      ),
    weekdaysShort: "Пнд_Втр_Срд_Чтв_Птн_Сбт_Вск".split("_"),
    weekdaysMin: "Пн_Вт_Ср_Чт_Пт_Сб_Вс".split("_"),
    weekdaysParseExact: true,
  },
  kg: {
    months: [
      "Үчтүн айы",
      "Бирдин айы",
      "Жалган Куран",
      "Чын Куран",
      "Бугу",
      "Кулжа",
      "Теке",
      "Баш Оона",
      "Аяк Оона",
      "Тогуздун айы",
      "Жетинин айы",
      "Бештин айы",
    ],
    monthsShort: "Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек".split("_"),
    monthsParseExact: true,
    weekdays: "Дүйшөмбү_Шейшемби_Шаршемби_Бейшемби_Жума_Ишемби_Жекшемби".split(
      "_"
    ),
    weekdaysShort: "Дүй_Шей_Шар_Бей_Жум_Ише_Жек".split("_"),
    weekdaysMin: "Дү_Ше_Ша_Бе_Жу_Иш_Же".split("_"),
    weekdaysParseExact: true,
  },
};
