import { makeRequest } from "api";
import { updateStoreData } from "store";
import { createStoreData } from "store";
import { get_employee, get_organization_list } from "./GET_requests";

export const employeeUpdate = (data, id) => (dispatch) => {
  makeRequest(`/employee/edit/${id}`, dispatch, "put", data).then((data) => {
    if (data) {
      dispatch(
        updateStoreData({
          key: "AdministrationDialog",
          data: { open: false },
        })
      );
      dispatch(get_employee(id));
      dispatch(
        createStoreData({
          alert: {
            message: "alerts.successfullyUpdated",
            severity: "success",
          },
        })
      );
    }
  });
};
export const organizationUpdate = (data, id) => (dispatch) => {
  makeRequest(`/Organisation/edit/${id}`, dispatch, "put", data).then(
    (data) => {
      if (data) {
        dispatch(
          updateStoreData({
            key: "AdministrationDialog",
            data: { open: false },
          })
        );
        dispatch(get_organization_list());
        dispatch(
          createStoreData({
            alert: {
              message: "alerts.successfullyUpdated",
              severity: "success",
            },
          })
        );
      }
    }
  );
};
