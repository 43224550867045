import React, { useState } from "react";
import { employeeCheck, get_inn_info } from "api";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { LoadingIndicator } from "shared/LoadingIndicator";
import { MenuItem, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { ValidatedTextInput } from "shared/StandardTextInput";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { StandardTextInput } from "shared/StandardTextInput";
import { PrimaryButton } from "shared/PrimaryButton";
import * as Yup from "yup";
import { createStoreData } from "store";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

export const Employee = ({
  onCreateOrUpdate,
  employee,
  isLoading,
  isShow,
  isCreate,
  isUpdate,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [dismissalDate, setDismissalDate] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [innOrganisation, setOrganization] = useState("");
  const [exception, setException] = useState({
    innEmployee: "",
  });
  let isOrganizationsDefined =
    Boolean(store.organizationListSelect) &&
    Boolean(store.organizationListSelect.length);
  const [employeeInitialValues, setInitialValues] = useState({
    innEmployee: Boolean(employee.inn) ? employee.inn : "",
    employeeName: Boolean(employee.name) ? employee.name : "",
    innOrganization: "",
    dbRole: Boolean(employee.dbModel) ? employee.dbModel : "",
    localPin: "",
  });
  const createEmployeeValidation = Yup.object({
    innEmployee: Yup.string()
      .min(14, t("errors.enterYourINNCorrectly"))
      .max(14, t("errors.enterYourINNCorrectly"))
      .required(t("errors.fillTheInput")),
    employeeName: Yup.string().required(t("errors.fillTheInput")),
    innOrganization: Yup.string().required("errors.selectOrganization"),
    dbRole: Yup.string().required("selectRole"),
    localPin: Yup.string()
      .max(4, t("errors.passwordIsIncorrect"))
      .required(t("errors.fillTheInput")),
  });

  React.useEffect(() => {
    if (employee.id) {
      setOrganization(employee.organisationInn);
      setAppointmentDate(employee.appointmentDate);
    }
  }, [employee]);
  React.useEffect(() => {
    if (Boolean(employee.organisationInn) && isOrganizationsDefined) {
      let organizationsFiltered = store.organizationListSelect.filter(
        (organization) => {
          return organization.inn === employee.organisationInn;
        }
      );
      setInitialValues((prevState) => ({
        ...prevState,
        innOrganization: Boolean(organizationsFiltered.length)
          ? organizationsFiltered[0].inn
          : t("errors.organizationsNotFound"),
      }));
    }
  }, [t, employee, isOrganizationsDefined, store.organizationListSelect]);

  const handleSubmit = (values) => {
    if (!Boolean(appointmentDate)) {
      return dispatch(
        createStoreData({
          alert: {
            severity: "error",
            message: t("errors.pickAppointmentDate"),
          },
        })
      );
    }
    let requestData = {
      ...values,
      isAdmin: false,
      innOrganisation,
      organisationName: store.organizationListSelect.filter(
        (item) => item.inn === innOrganisation
      )[0].name,
      appointmentDate: moment(appointmentDate).toISOString(),
    };

    if (isUpdate && Boolean(dismissalDate)) {
      requestData.dismissalDate = dismissalDate;
    }
    if (isUpdate) {
      return onCreateOrUpdate(requestData, employee.id);
    }

    onCreateOrUpdate(requestData);
    setDismissalDate(null);
  };
  const employeeInnChange = (value) => {
    if (value.length === 14) {
      dispatch(
        get_inn_info(value, (data) => {
          if (data) {
            setException({
              ...exception,
              innEmployee: data.name,
            });
            dispatch(employeeCheck(value));
          }
        })
      );
    }
    if (Boolean(exception.innEmployee)) {
      setException({ ...exception, innEmployee: "" });
    }
  };
  return isLoading ? (
    <div
      className="flex_box"
      style={{ width: "100%", padding: 20, justifyContent: "center" }}>
      <LoadingIndicator />
    </div>
  ) : isShow ? (
    <>
      <Typography variant="body1" style={{ marginBottom: 24 }}>
        {Boolean(employee.organisationName)
          ? employee.organisationName
          : t("errors.organizationNameNotFound")}
      </Typography>
      {/* {Boolean(employee.division) && (
        <Typography variant="body1">{employee.division}</Typography>
      )}
      {Boolean(employee.position) && (
        <Typography variant="body1" style={{ padding: "10px 0px" }}>
          {employee.position}
        </Typography>
      )} */}
      <Typography variant="body1">
        {t("admin_page.appointmentDateValue", {
          appointment_date: moment(appointmentDate).format("DD MMMM YYYY"),
        })}
      </Typography>
    </>
  ) : (
    <Formik
      validationSchema={createEmployeeValidation}
      onSubmit={handleSubmit}
      initialValues={employeeInitialValues}
      enableReinitialize>
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <ValidatedTextInput
            style={{
              marginBottom: 40,
            }}
            value={formik.values.innEmployee}
            onChange={(e) => {
              if (e.target.value.length >= 14) {
                e.target.value = e.target.value.substr(0, 14);
              }
              formik.handleChange(e);
              employeeInnChange(e.target.value);
            }}
            onBlur={formik.handleBlur}
            variant="standard"
            name="innEmployee"
            label={t("forms.employeeINN")}
            exception={exception["innEmployee"]}
            disabled={isUpdate}
            fullWidth
          />
          <ValidatedTextInput
            style={{
              marginBottom: 40,
            }}
            value={formik.values["employeeName"]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="standard"
            name="employeeName"
            label={t("forms.fullNameOfTheEmployee")}
            disabled={isUpdate}
            fullWidth
          />
          {isUpdate ? (
            <ValidatedTextInput
              style={{
                marginBottom: 40,
              }}
              value={formik.values["innOrganization"]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant="standard"
              name="innOrganization"
              label={t("forms.organizationINN")}
              disabled={isUpdate}
              multiline
              fullWidth
            />
          ) : (
            <ValidatedTextInput
              style={{
                marginBottom: 40,
              }}
              value={formik.values["innOrganization"]}
              onChange={(e) => {
                formik.handleChange(e);
                setOrganization(e.target.value);
              }}
              SelectProps={{
                MenuProps: { className: classes.organizationsMenu },
              }}
              variant="standard"
              name="innOrganization"
              label={t("forms.organizationINN")}
              disabled={isUpdate}
              fullWidth
              select>
              {isOrganizationsDefined ? (
                store.organizationListSelect.map((organization) => (
                  <MenuItem key={organization.id} value={organization.inn}>
                    {organization.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="no_data">
                  {t("errors.organizationsNotFound")}
                </MenuItem>
              )}
            </ValidatedTextInput>
          )}
          <ValidatedTextInput
            style={{
              marginBottom: 40,
            }}
            value={formik.values["dbRole"]}
            onChange={formik.handleChange}
            variant="standard"
            name="dbRole"
            label={t("forms.role")}
            fullWidth
            select>
            {Boolean(store.dbRoleListSelect) &&
            store.dbRoleListSelect.length > 0 ? (
              store.dbRoleListSelect.map((dbRole) => (
                <MenuItem key={dbRole.dbRoleId} value={dbRole.name}>
                  {dbRole.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="no_data">{t("errors.rolesNotFound")}</MenuItem>
            )}
          </ValidatedTextInput>
          <ValidatedTextInput
            style={{
              marginBottom: 40,
            }}
            value={formik.values.localPin}
            onChange={(event) => {
              if (event.target.value.length >= 4) {
                event.target.value = event.target.value.substr(0, 4);
              }
              formik.handleChange(event);
            }}
            onBlur={formik.handleBlur}
            variant="standard"
            name="localPin"
            label={t("forms.password")}
            fullWidth
          />
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            locale={i18n.language}>
            <DesktopDatePicker
              value={appointmentDate}
              onChange={(newDate) => setAppointmentDate(newDate)}
              maxDate={moment()}
              label={t("forms.appointmentDate")}
              name="appointmentDate"
              inputFormat="DD.MM.yyyy"
              mask="__.__.____"
              renderInput={(params) => (
                <StandardTextInput
                  {...params}
                  style={{
                    marginBottom: 40,
                  }}
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          {isUpdate && (
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              locale={i18n.language}>
              <DesktopDatePicker
                value={dismissalDate}
                onChange={(newDate) => setDismissalDate(newDate)}
                maxDate={moment()}
                label={t("forms.dismissalDate")}
                name="dismissalDate"
                inputFormat="DD.MM.yyyy"
                mask="__.__.____"
                renderInput={(params) => (
                  <StandardTextInput
                    {...params}
                    style={{
                      marginBottom: 40,
                    }}
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          )}
          <div
            className="flex_box"
            style={{ width: "100%", justifyContent: "center" }}>
            <PrimaryButton
              style={{ width: "50%" }}
              variant="contained"
              type="submit"
              disabled={
                !Boolean(
                  employeeInitialValues.innEmployee !==
                    formik.values["innEmployee"] ||
                    employeeInitialValues.employeeName !==
                      formik.values["employeeName"] ||
                    employeeInitialValues.innOrganization !==
                      formik.values["innOrganization"] ||
                    employeeInitialValues.dbRole !== formik.values["dbRole"] ||
                    employeeInitialValues.appointmentDate !== appointmentDate
                )
              }>
              {isCreate ? t("general.create") : t("general.save")}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const useStyles = makeStyles((theme) => ({
  organizationsMenu: {
    "& .MuiPaper-root": {
      maxWidth: 352,
    },
    "& .MuiPaper-root li": {
      whiteSpace: "normal",
    },
  },
}));
