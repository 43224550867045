import React from "react";
import { withStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { ErrorMessage, useField } from "formik";

export const StandardTextInput = withStyles((theme) => ({
  root: {
    "& .MuiInput-root:befor": { borderBottom: "1px solid #C4C4C4" },
    "& .MuiInputBase-input": {
      fontFamily: "Inter",
      fontSize: 16,
      lineHeight: "150%",
    },
    "& .MuiInputLabel-root": {
      color: "#1C5C91",
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "Inter",
      fontSize: 14,
      color: "#757575",
      marginLeft: 0,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#d32f2f",
    },
    "& .Mui-disabled": {
      WebkitTextFillColor: "#000",
    },
  },
}))(TextField);

export const ValidatedTextInput = React.forwardRef(
  ({ exception, ...props }, ref) => {
    const [field, meta] = useField(props);
    let error = Boolean(meta.touched && meta.error);
    return (
      <StandardTextInput
        ref={ref}
        value={field.value}
        {...props}
        error={error}
        helperText={
          Boolean(exception) ? (
            exception
          ) : error ? (
            <ErrorMessage name={field.name} />
          ) : (
            ""
          )
        }
        fullWidth
      />
    );
  }
);
