import cookie_js from "cookie_js";
import moment from "moment";
import { appAxios, get_db_list, get_employee_info, makeRequest } from "api";
import { createStoreData, updateStoreData } from "store";
import {
  getDataTableFromClassifierRecords,
  getDataTableSample,
  getTreeStructure,
  getUrlString,
} from "./utils";
import {
  getClassifiersAndDirectories,
  get_employee_list,
  get_fk_variables,
  get_organization_list,
} from "./GET_requests";
import { initialState } from "store/initialState";

export const organizationCheck = (inn, callback) => (dispatch) => {
  makeRequest("/Auth/check", dispatch, "post", { inn });
};

export const employeeCheck = (inn, callback) => (dispatch) =>
  makeRequest("/Auth/employee/check", dispatch, "post", { inn });

export const employeeConfirm = (data, callback) => (dispatch) =>
  makeRequest("/Auth/employee/pin/confirmNew", dispatch, "post", data).then(
    (json) => {
      if (json) {
        cookie_js.set("metadata_token", `Bearer ${json.token}`, {
          expires: new Date(
            moment().add(1, "day").toLocaleString()
          ).toUTCString(),
          path: "/",
        });
        appAxios.defaults.headers["Authorization"] = `Bearer ${json.token}`;
        dispatch(
          createStoreData({
            ...initialState,
            employee: {
              ...json,
              isSuperAdmin: json.innEmployee === "33223322332233",
            },
          })
        );
        dispatch(get_employee_info());
        callback();
      }
    }
  );

export const employeeName = (inn) => (dispatch) =>
  makeRequest("/Auth/common/name/by_inn", dispatch, { inn });

export const employeeAdd = (data) => (dispatch) => {
  makeRequest("/employee/add", dispatch, "post", data).then((data) => {
    if (data) {
      dispatch(
        updateStoreData({
          key: "AdministrationDialog",
          data: { open: false },
        })
      );
      dispatch(
        createStoreData({
          alert: {
            message: "alerts.successfullyCreated",
            severity: "success",
          },
        })
      );
      dispatch(get_employee_list({ pageNumber: 1, pageSize: 10 }));
    }
  });
};
export const organizationAdd = (data) => (dispatch) => {
  makeRequest("/Organisation/create", dispatch, "post", data).then((data) => {
    if (data) {
      dispatch(
        updateStoreData({
          key: "AdministrationDialog",
          data: { open: false },
        })
      );
      dispatch(get_organization_list());
      dispatch(
        createStoreData({
          alert: {
            message: "alerts.successfullyCreated",
            severity: "success",
          },
        })
      );
    }
  });
};
export const createNewDB = (data, callback) => (dispatch) => {
  makeRequest("/Db/create", dispatch, "post", data).then((json) => {
    if (json) {
      if (
        data.formType === process.env.REACT_APP_VARIABLES_IT ||
        data.formType === process.env.REACT_APP_VARIABLES_CONCEPTUAL ||
        data.formType === process.env.REACT_APP_DEFINITIONS
      ) {
        dispatch(get_fk_variables(() => callback(json.id)));
      } else if (
        data.formType === process.env.REACT_APP_CLASSIFIER ||
        data.formType === process.env.REACT_APP_CATALOG
      ) {
        dispatch(getClassifiersAndDirectories(() => callback(json.id)));
      } else {
        dispatch(
          get_db_list({ formTypes: data.formType }, () => callback(json.id))
        );
      }
    } else {
      callback();
    }
  });
};
export const variablesComplexCreation = (data, callback) => (dispatch) => {
  makeRequest("/Db/create2", dispatch, "post", data).then((json) => {
    if (json) {
      dispatch(get_fk_variables(() => callback(true)));
    } else {
      callback(false);
    }
  });
};
export const updateSchema =
  (data, formId, callback = () => {}) =>
  (dispatch) => {
    makeRequest(
      `/Db/create-schema/${formId}`,
      dispatch,
      "post",
      data.schema
    ).then((json) => {
      if (json) {
        dispatch(updateStoreData({ key: "schemas", data: { [formId]: data } }));
        dispatch(
          createStoreData({
            alert: {
              message: "alerts.successfullySaved",
              severity: "success",
            },
          })
        );
        callback(true);
      } else {
        callback();
      }
    });
  };
export const createDataBaseRecord =
  (data, formId, callback, params) => (dispatch) => {
    makeRequest(
      `/Db/create-record/${formId}?${params ? getUrlString(params) : ""}`,
      dispatch,
      "post",
      data
    ).then((json) => {
      if (json) {
        dispatch(
          createStoreData({
            alert: {
              message: "alerts.successfullyCreated",
              severity: "success",
            },
          })
        );
        callback(json);
      } else {
        callback();
      }
    });
  };
export const search_in_database = (data, callback) => (dispatch) => {
  makeRequest("Db/search", dispatch, "post", data).then((json) => {
    if (json) {
      dispatch(
        updateStoreData({
          key: "maxCounts",
          data: {
            [data.dbId]: json.result.pageObject.rowCount,
          },
        })
      );
      dispatch(
        updateStoreData({
          key: "maxPages",
          data: {
            [data.dbId]: json.result.pageObject.pageCount,
          },
        })
      );
      dispatch(
        updateStoreData({
          key: "dataTables",
          data: { [data.dbId]: getDataTableSample(json.result) },
        })
      );
    }
    if (callback) {
      callback();
    }
  });
};
export const search_in_hierarchical_database =
  (data, callback) => (dispatch) => {
    makeRequest("Db/search", dispatch, "post", data).then((json) => {
      if (json) {
        dispatch(
          updateStoreData({
            key: "maxCounts",
            data: {
              ["hierarchical&" + data.dbId]: json.result.pageObject.rowCount,
            },
          })
        );
        dispatch(
          updateStoreData({
            key: "maxPages",
            data: {
              ["hierarchical&" + data.dbId]: json.result.pageObject.pageCount,
            },
          })
        );
        dispatch(
          updateStoreData({
            key: `hierarchicals.${data.dbId}`,
            data: { [data.page]: getTreeStructure(json.result) },
          })
        );
      }
      if (callback) {
        callback();
      }
    });
  };
export const getDBRoleList = () => (dispatch) => {
  makeRequest("Db/DbRole/list", dispatch, "post", {}).then((json) => {
    if (json) {
      let sortedResult = json.result.sort((a, b) => {
        return a.name.charCodeAt(0) - b.name.charCodeAt(0);
      });

      dispatch(
        updateStoreData({
          key: "dataTables",
          data: {
            dbRoleList: getDataTableFromClassifierRecords(sortedResult, false, [
              ["№"],
              [
                {
                  label: "forms.roleName",
                  model: "name",
                },
              ],
              [
                {
                  label: "forms.description",
                  model: "description",
                },
              ],
              [
                {
                  label: "general.action",
                  model: "administration",
                },
              ],
            ]),
          },
        })
      );
      dispatch(createStoreData({ dbRoleListSelect: sortedResult }));
    } else {
      dispatch(
        createStoreData({
          alert: {
            severity: "info",
            message: "errors.roles_not_found",
          },
        })
      );
    }
  });
};
export const createOrUpdateDBRole = (data) => (dispatch) => {
  makeRequest("Db/DbRole/set", dispatch, "post", data).then((json) => {
    if (json) {
      dispatch(
        updateStoreData({
          key: "AdministrationDialog",
          data: { open: false },
        })
      );
      dispatch(getDBRoleList());
      dispatch(
        createStoreData({
          alert: {
            severity: "success",
            message: "alerts.successfully",
          },
        })
      );
    }
  });
};
export const uploadExcelToImport = (data, callback) => (dispatch) => {
  makeRequest("Db/upload-file", dispatch, "post", data).then((json) => {
    if (json) {
      callback();
    }
  });
};
export const uploadExcelToImportHierarchical =
  (data, callback) => (dispatch) => {
    makeRequest("Db/upload-file-recurse-records", dispatch, "post", data).then(
      (json) => {
        if (json) {
          callback();
        }
      }
    );
  };
export const updateMetadata =
  (formId, params, data, callback) => (dispatch) => {
    makeRequest(
      `Db/UpdateMetadata/${formId}?${getUrlString(params)}`,
      dispatch,
      "post",
      data
    ).then((json) => {
      if (json) {
        callback(true);
      } else {
        callback(false);
      }
    });
  };
export const uploadDocuments = (data, callback) => (dispatch) => {
  let promiseArr = data.map((document) =>
    makeRequest("Db/File/Create", dispatch, "post", document).then((json) => {
      delete document.fileBase64;
      return { ...document, fileId: json.result };
    })
  );

  Promise.all(promiseArr)
    .then((jsonArr) => {
      if (jsonArr) {
        callback(jsonArr);
      }
    })
    .catch((error) => {
      console.error(error);
      callback(false);
    });
};
export const searchDataBaseByName =
  (data, callback = () => {}) =>
  (dispatch) => {
    makeRequest("/Db/SearchForm", dispatch, "post", data).then((json) => {
      if (json) {
        if (!Boolean(json.result.length)) {
          dispatch(
            updateStoreData({
              key: "maxCounts",
              data: {
                dbList: json.result.length,
              },
            })
          );
          return dispatch(createStoreData({ dbList: false }));
        }

        let dbList = json.result
          .filter((db) => db.formType !== process.env.REACT_APP_DATABASE)
          .map((db) => {
            switch (db.formType) {
              case process.env.REACT_APP_CATALOG: {
                db.path = `/directories/${db.id}`;
                break;
              }
              case process.env.REACT_APP_CLASSIFIER: {
                db.path = `/classifiers/${db.id}`;
                break;
              }
              case process.env.REACT_APP_DEFINITIONS: {
                db.path = `/definitions/${db.id}`;
                break;
              }
              case process.env.REACT_APP_INDICATORS: {
                db.path = `/about-statistic/${db.id}`;
                break;
              }
              case process.env.REACT_APP_VARIABLES_CONCEPTUAL:
              case process.env.REACT_APP_VARIABLES_IT: {
                db.path = `/variables/${db.id}`;
                break;
              }
              default: {
                db.path = null;
                break;
              }
            }
            return db;
          });

        if (!Boolean(data.formName)) {
          dispatch(createStoreData({ dbList }));
          dispatch(
            updateStoreData({
              key: "maxCounts",
              data: {
                dbList: dbList.length,
              },
            })
          );
        } else {
          callback(dbList);
        }
      } else {
        callback(false);
      }
    });
  };
