import axios from "axios";
import cookie_js from "cookie_js";
import { createStoreData } from "store/AppReducer";
import { initialState } from "store/initialState";

let token = cookie_js.get("metadata_token");

export const appAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  contentType: "application/json",
  headers: {
    Authorization: token ? token : process.env.REACT_APP_GUEST_TOKEN,
  },
});

export const makeRequest = (endpoint, dispatch, method, data) => {
  function errorHandler({ response }) {
    if (
      (response.data.message || response.data.title) === "информация не найдена"
    ) {
      return;
    }
    if (response?.status === 401) {
      cookie_js.remove("metadata_token");
      appAxios.defaults.headers["Authorization"] =
        process.env.REACT_APP_GUEST_TOKEN;

      dispatch(
        createStoreData({
          ...initialState,
          employee: { token: process.env.REACT_APP_GUEST_TOKEN },
          alert: {
            message: response.data.message || response.data.title,
            severity: "error",
          },
        })
      );
      return;
    }
    let isMessageDefined = Boolean(response?.data.message);
    if (
      response?.data.message !==
      "Object reference not set to an instance of an object."
    ) {
      dispatch(
        createStoreData({
          alert: {
            message: "Структура не коррекно",
            severity: "error",
          },
        })
      );
      dispatch(
        createStoreData({
          alert: {
            message: isMessageDefined
              ? response?.data.message
              : `Внимание: Ошибка в запросе на endpoint ${endpoint}`,
            severity: "error",
          },
        })
      );
    }
    console.error(`Внимание: Ошибка в запросе на endpoint ${endpoint}. 
    Текс сообщения: ${
      isMessageDefined ? response?.data.message : "Не найден"
    }.`);
  }

  switch (method) {
    case "get": {
      return appAxios
        .get(endpoint)
        .then((json) => json.data)
        .catch(errorHandler);
    }
    case "post": {
      return appAxios
        .post(endpoint, data)
        .then((json) => json.data)
        .catch(errorHandler);
    }
    case "put": {
      return appAxios
        .put(endpoint, data)
        .then((json) => json.data)
        .catch(errorHandler);
    }
    case "delete": {
      return appAxios
        .delete(endpoint)
        .then((json) => json.data)
        .catch(errorHandler);
    }
    default: {
      dispatch(
        createStoreData({
          alert: {
            message: `Метод запроса не найден: ${endpoint}`,
            severity: "error",
          },
        })
      );
    }
  }
};

export * from "./utils";
export * from "./formsData";
export * from "./POST_requests";
export * from "./GET_requests";
export * from "./PUT_requests";
export * from "./DELETE_requests";
